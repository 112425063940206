import React from 'react'
import DateField from 'shared/forms/DateField'

function BgAccountLastUpdate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Account Last Update"
      showTitle={true}
      accessorKey="AccountLastUpdate"
      sectionAbbreviation="bg"
    />
  )
}

export default BgAccountLastUpdate