import React from 'react'
import DateField from 'shared/forms/DateField'

function InvoiceDueDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Invoice Due Date"
      showTitle={true}
      accessorKey="InvoiceDueDate"
      sectionAbbreviation="ap"
    />
  )
}

export default InvoiceDueDate
