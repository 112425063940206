import React from 'react'

function TextCell({ value }) {
  return (
    <td className="align-middle text-muted fw-light">
      {value}
    </td>
  )
}

export default TextCell