import React from 'react'
import NumberField from 'shared/forms/NumberField'

function BgMaxBudgetPercentOfOriginal({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Max Budget % of Original"
      showTitle={true}
      accessorKey="bgMaxBudgetPercentOfOriginal"
      isPercent={true}
    />
  )
}

export default BgMaxBudgetPercentOfOriginal
