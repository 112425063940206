import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import {
  useCreateDashboardColumn,
  useDeleteDashboardColumn
} from 'api/hooks/use-dashboard-columns'
import { useQueryClient } from 'react-query'

function UpdateColumn({ column, dashboardId, currentColumns }) {
  const queryClient = useQueryClient()
  const [isChecked, setIsChecked] = useState(() => 
    currentColumns.map((c) => c.id).includes(column.id)
  )
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(`dashboards/${dashboardId}`)
    }
  }
  const params = {
    dashboard_id: dashboardId,
    table_column_id: column.id
  }

  const { mutate: addColumn } = useCreateDashboardColumn({ params, options })
  const { mutate: deleteColumn } = useDeleteDashboardColumn({
    id: currentColumns.find(
      (c) => c.id === column.id
    )?.dashboard_column_id,
    options
  })

  const handleToggle = (e) => {
    setIsChecked(e.target.checked)
    if (e.target.checked) {
      addColumn()
    } else {
      deleteColumn()
    }
  }

  return (
    <div key={`check-${column.id}`} className="mt-2">
      <Form.Check
        type="checkbox"
        label={
          <>
            {column.name}
            {/* <div className='text-muted'>{column.description}</div> */}
          </>
        }
        id={column.id}
        onChange={handleToggle}
        checked={isChecked}
      />
    </div>
  )
}

export default UpdateColumn
