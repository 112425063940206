import React from 'react'
import NumberField from 'shared/forms/NumberField'

function BgMaxBudgetPercentRemaining({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Max Budget % Remaining"
      showTitle={true}
      accessorKey="bgMaxBudgetPercentRemaining"
      isPercent={true}
    />
  )
}

export default BgMaxBudgetPercentRemaining
