import React from 'react'
import DateField from 'shared/forms/DateField'

function EntryDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Entry Date"
      showTitle={true}
      accessorKey="EntryDate"
      sectionAbbreviation="gl"
    />
  )
}

export default EntryDate