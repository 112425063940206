import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

function BooleanCell({ value }) {
  return (
    <td className="align-middle fw-light">
      {value ?
        <FontAwesomeIcon icon={faFlag} className="text-danger" />
        :
        <FontAwesomeIcon icon={faTimes} className="text-info fw-light" />
      }
    </td>
  )
}

export default BooleanCell