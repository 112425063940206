import React from 'react'
import CheckboxField from 'shared/forms/CheckboxField'

const IsDuplicateEin = ({ params, setParams }) => {
  return (
    <CheckboxField
      params={params}
      setParams={setParams}
      accessorKey="voIsDuplicateEin"
      label="Is Duplicate EIN"
      helperText=""
    />
  )
}

export default IsDuplicateEin