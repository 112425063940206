import React, { useState, useEffect, useContext } from 'react'
import Datetime from 'react-datetime'
import { Row, Col } from 'react-bootstrap'
import UserContext from 'context/user-context'

function DateField({
  title,
  params,
  setParams,
  dateFormat = 'MM/DD/YYYY',
  accessorKey = '',
  sectionAbbreviation = ''
}) {
  const { fiscal_year_start } = useContext(UserContext)
  const [year, month, day] = fiscal_year_start.split('-').map(Number)
  const fiscalYearStart = new Date(year, month - 1, day) // Month is 0-indexed
  const options = [
    {
      label: 'Comparison',
      options: [
        { value: 'any', label: 'Any Date' },
        { value: 'less', label: 'Before' },
        { value: 'greater', label: 'After' },
        { value: 'between', label: 'Between' }
      ]
    },
    {
      label: 'Relative',
      options: [
        { value: '30', label: 'Past 30 days' },
        { value: '60', label: 'Past 60 days' },
        { value: '90', label: 'Past 90 days' },
        { value: '120', label: 'Past 120 days' }
      ]
    },
    {
      label: 'Quarters',
      options: [
        { value: 'Q1', label: 'Q1' },
        { value: 'Q2', label: 'Q2' },
        { value: 'Q3', label: 'Q3' },
        { value: 'Q4', label: 'Q4' }
      ]
    },
    {
      label: 'Fiscal Year',
      options: [
        { value: 'PFY', label: 'Previous Fiscal Year' },
        { value: 'FY', label: 'Current Fiscal Year' },
        { value: 'YTD', label: 'Year to Date' }
      ]
    }
  ]

  const minAccessor = `${sectionAbbreviation}Min${accessorKey}`
  const maxAccessor = `${sectionAbbreviation}Max${accessorKey}`
  const optionAccessor = `${sectionAbbreviation}${accessorKey}Option`
  const [operator, setOperator] = useState(params[optionAccessor] || 'any')

  useEffect(() => {
    const dateRanges = {
      30: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      60: [new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
      90: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
      120: [
        new Date(new Date().setDate(new Date().getDate() - 120)),
        new Date()
      ],
      PFY: [
        new Date(
          fiscalYearStart.getFullYear() - 1,
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate() - 1
        )
      ],
      FY: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear() + 1,
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate() - 1
        )
      ],
      YTD: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate()
        ),
        new Date()
      ],
      Q1: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth(),
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 3,
          0
        )
      ],
      Q2: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 3,
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 6,
          0
        )
      ],
      Q3: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 6,
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 9,
          0
        )
      ],
      Q4: [
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 9,
          fiscalYearStart.getDate()
        ),
        new Date(
          fiscalYearStart.getFullYear(),
          fiscalYearStart.getMonth() + 12,
          0
        )
      ],
      any: [null, null]
    }

    const [minDate, maxDate] = dateRanges[operator] || [null, null]

    setParams({
      ...params,
      [minAccessor]: minDate,
      [maxAccessor]: maxDate,
      [optionAccessor]: operator
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator])

  return (
    <div>
      <Row>
        <Col sm="auto">
          <label className="form-label mb-0">
            {title}
            {' is '}
            <select
              className="form-select d-inline w-auto ms-2"
              name={title}
              value={operator}
              onChange={(e) => setOperator(e.target.value)}
            >
              {options.map((group) => (
                <optgroup key={group.label} label={group.label}>
                  {group.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </label>
        </Col>
        {operator === 'greater' && (
          <Col sm="auto">
            <Datetime
              dateFormat={dateFormat}
              input={true}
              placeholderText="Start Date"
              timeFormat={false}
              onChange={(e) =>
                setParams({ ...params, [minAccessor]: e.toDate() })
              }
              value={params[minAccessor]}
            />
          </Col>
        )}
        {operator === 'less' && (
          <Col sm="auto">
            <Datetime
              dateFormat={dateFormat}
              input={true}
              placeholderText="End Date"
              timeFormat={false}
              onChange={(e) =>
                setParams({ ...params, [maxAccessor]: e.toDate() })
              }
              value={params[maxAccessor]}
            />
          </Col>
        )}
      </Row>
      {operator === 'between' && (
        <Row className="mt-2">
          <Col>
            <Datetime
              dateFormat={dateFormat}
              input={true}
              placeholder="Start Date"
              timeFormat={false}
              onChange={(e) =>
                setParams({ ...params, [minAccessor]: e.toDate() })
              }
              value={params[minAccessor]}
            />
          </Col>
          <Col>
            <Datetime
              dateFormat={dateFormat}
              input={true}
              placeholderText="End Date"
              timeFormat={false}
              onChange={(e) =>
                setParams({ ...params, [maxAccessor]: e.toDate() })
              }
              value={params[maxAccessor]}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default DateField
