import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SortArrow from 'shared/tables/sort-arrow'
import UserContext from 'context/user-context'

function TableHeader({ tableFilters, setTableFilters, columns, includeOrdering = true }) {
  const { municipality } = useContext(UserContext)
  const updateOrdering = (column) => {
    const isCurrentColumn = tableFilters.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setTableFilters({
      ...tableFilters,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return tableFilters.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  return (
    <thead>
      <tr>
        {columns.includes('cleared') && <th></th>}
        {columns.includes('save') && <th></th>}
        {columns.includes('id') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            ID{' '}
            {includeOrdering && (
              <SortArrow
                column="id"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('view') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            <span className="ms-2">ID </span>
          </th>
        )}
        {columns.includes('processing_modal') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            ID{' '}
            {includeOrdering && (
              <SortArrow
                column="id"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('report_analytics_count') && (
          <th
            onClick={() => updateOrdering('report_analytics_count')}
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '8%'
            }}
          >
            Flags
            {includeOrdering && (
              <SortArrow
                column="report_analytics_count"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('risk_score') && (
          <th
            onClick={() => updateOrdering('risk_score')}
            style={{
              width: '8%'
            }}
          >
            Risk
          </th>
        )}
        {columns.includes('analytics_count') && (
          <th
            onClick={() => updateOrdering('analytics_count')}
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '8%'
            }}
          >
            Flags
            {includeOrdering && (
              <SortArrow
                column="analytics_count"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('single-status') && (
          <th
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
            className="text-center"
          >
            Overall
          </th>
        )}
        {columns.includes('testing-status') && (
          <th
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '18%'
            }}
            className="text-center"
          >
            Attributes
          </th>
        )}
        {columns.includes('status') && (
          <th
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '20%'
            }}
            className="text-center"
          >
            Transaction Status
          </th>
        )}
        {columns.includes('transaction_on') && (
          <th
            onClick={() => updateOrdering('transaction_on')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Date{' '}
            {includeOrdering && (
              <SortArrow
                column="transaction_on"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('line_amount') && (
          <th
            onClick={() => updateOrdering('line_amount')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Amount{' '}
            {includeOrdering && (
              <SortArrow
                column="line_amount"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('vendor') && (
          <th
            onClick={() => updateOrdering('vendors.name')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Vendor{' '}
            {includeOrdering && (
              <SortArrow
                column="vendors.name"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('vendor_name') && <th>Vendor </th>}
        {columns.includes('employee') && <th>Employee </th>}
        {columns.includes('employee_name') && <th>Employee </th>}
        {/* ap_transaction_details */}
        {columns.includes('vendor_type') && <th>Vendor Type</th>}
        {columns.includes('vendor_external_id') && <th>Vendor ID</th>}
        {columns.includes('vendor_minority_code') && (
          <th>Vendor Minority Code</th>
        )}
        {columns.includes('invoice_number') && <th>Invoice Number </th>}
        {columns.includes('invoice_amount') && <th>Invoice Amount</th>}
        {columns.includes('invoice_to_entry_days') && (
          <th>Invoice to Entry (Days)</th>
        )}
        {columns.includes('entry_to_payment_days') && (
          <th>Entry to Check Date (Days)</th>
        )}
        {columns.includes('total_days_to_payment') && (
          <th>Invoice to Check Date (Days)</th>
        )}
        {columns.includes('po_number') && <th>PO #</th>}
        {columns.includes('po_entry_date') && <th>PO Entry Date</th>}
        {columns.includes('invoice_entered_by') && <th>Entered By</th>}
        {columns.includes('invoice_date') && <th>Invoice Date</th>}
        {columns.includes('invoice_due_date') && <th>Invoice Due Date</th>}
        {columns.includes('invoice_gl_effective_date') && (
          <th>GL Effective Date</th>
        )}
        {columns.includes('invoice_department_code') && (
          <th>Department Code</th>
        )}
        {columns.includes('invoice_status') && <th>Invoice Status</th>}
        {columns.includes('invoice_description') && <th>Description</th>}
        {columns.includes('check_amount') && <th>Check Amount</th>}
        {columns.includes('check_void_amount') && <th>Void Amount</th>}
        {columns.includes('check_type') && <th>Payment Type</th>}
        {columns.includes('check_date') && <th>Check Date</th>}
        {columns.includes('check_clear_date') && <th>Clear Date</th>}
        {columns.includes('check_date_to_clear_days') && (
          <th>Check to Clear (Days)</th>
        )}
        {/* gl_transaction_details */}
        {columns.includes('full_account') && <th>Account</th>}
        {columns.includes('year') && <th>Year</th>}
        {columns.includes('period') && <th>Period</th>}
        {columns.includes('entry_date') && <th>Entry Date</th>}
        {columns.includes('effective_date') && <th>GL Effective Date</th>}
        {columns.includes('posted_date') && <th>Posted Date</th>}
        {columns.includes('entry_to_effective_days') && (
          <th>Entry to Effective Date (Days)</th>
        )}
        {columns.includes('debit_credit') && <th>Debit/Credit</th>}
        {columns.includes('debit') && <th>Debit</th>}
        {columns.includes('credit') && <th>Credit</th>}
        {columns.includes('comment') && <th>Comment</th>}
        {columns.includes('journal_number') && <th>Journal Number</th>}
        {/* pcard_transaction_details */}
        {columns.includes('statement_id') && <th>Statement ID</th>}
        {columns.includes('doc_number') && <th>Doc Number</th>}
        {columns.includes('card_limit') && <th>Card Limit</th>}
        {columns.includes('card_number') && <th>Card Number</th>}
        {columns.includes('card_single_purchase_limit') && (
          <th>Single Purchase Limit</th>
        )}

        {columns.includes('vendor_phone_number') && <th>Phone</th>}
        {columns.includes('vendor_type') && <th>Vendor Type</th>}
        {columns.includes('vendor_status') && <th>Vendor Status</th>}
        {columns.includes('address') && <th>Address</th>}
        {columns.includes('city') && <th>City</th>}
        {columns.includes('state') && <th>State</th>}
        {columns.includes('zip') && <th>Zip</th>}
        {columns.includes('created_on') && <th>Created On</th>}
        {columns.includes('created_by_id') && <th>Created By</th>}
        {columns.includes('last_invoice_on') && <th>Last Invoice On</th>}
        {columns.includes('last_check_on') && <th>Last Check On</th>}
        {columns.includes('previous_year_po_sum') && <th>PO Sum PY</th>}
        {columns.includes('current_year_po_sum') && <th>PO Sum CY</th>}
        {columns.includes('past_month_po_sum') && (
          <th>PO Sum Change (Past Month)</th>
        )}
        {columns.includes('past_week_po_sum') && (
          <th>PO Sum Change (Past Week)</th>
        )}
        {columns.includes('current_year_po_count') && <th>PO Count CY</th>}
        {columns.includes('current_year_contract_count') && (
          <th>Contract Count CY</th>
        )}
        {columns.includes('current_year_contracts') && <th>Contracts #s</th>}
        {columns.includes('is_800_number') && <th>800 Number</th>}
        {columns.includes('is_po_box') && <th>PO Box</th>}
        {columns.includes('is_duplicate_ein') && <th>Duplicate EIN</th>}
        {columns.includes('minority_code') && <th>Minority Code</th>}
        {columns.includes('req_type') && <th>Req Type</th>}
        {columns.includes('req_to_po_creation') && <th>Req to PO Creation</th>}
        {columns.includes('req_prepared_date') && <th>Req Prepared Date</th>}
        {columns.includes('req_buyer_id') && <th>Buyer</th>}
        {columns.includes('req_entered_by_id') && <th>Entered By</th>}
        {columns.includes('req_total_amount') && <th>Req Total Amount</th>}
        {columns.includes('req_general_comment') && <th>Comment</th>}
        {columns.includes('po_department_code') && <th>Department</th>}
        {columns.includes('po_entry_date') && <th>PO Entry Date</th>}
        {columns.includes('po_change_date') && <th>PO Change Date</th>}
        {columns.includes('po_gl_effective_date') && <th>GL Effective Date</th>}
        {columns.includes('po_status_code') && <th>PO Status</th>}
        {columns.includes('po_ship_to_address') && <th>Ship To</th>}
        {columns.includes('po_liquidated_amount') && <th>Liquidated Amount</th>}
        {columns.includes('po_remaining_amount') && <th>Remaining Amount</th>}
        {columns.includes('po_percent_liquidated') && (
          <th>Percent Liquidated</th>
        )}
        {columns.includes('is_pending_workflow') && <th>Pending Workflows</th>}
        {/* bg_transaction_details */}
        {columns.includes('org') && <th>Org</th>}
        {columns.includes('object') && <th>Object</th>}
        {columns.includes('project') && <th>Project</th>}
        {columns.includes('budget_year') && <th>Budget Year</th>}
        {columns.includes('account_status') && <th>Account Status</th>}
        {columns.includes('account_type') && <th>Account Type</th>}
        {columns.includes('balance_type') && <th>Balance Type</th>}
        {columns.includes('account_last_update') && (
          <th>Account Last Update</th>
        )}
        {columns.includes('normal_balance_type') && <th></th>}
        {columns.includes('original_budget_cy') && <th>Original Budget</th>}
        {columns.includes('transfer_in_cy') && <th>Transfer In</th>}
        {columns.includes('transfer_out_cy') && <th>Transfer Out</th>}
        {columns.includes('revised_py') && <th>Revised Budget PY</th>}
        {columns.includes('revised_cy') && <th>Revised Budget</th>}
        {columns.includes('actuals_cy') && <th>Actuals</th>}
        {columns.includes('encumbrance_cy') && <th>Encumbrance</th>}
        {columns.includes('budget_amount_remaining') && (
          <th>Budget Remaining</th>
        )}
        {columns.includes('budget_percent_remaining') && <th>% Remaining</th>}
        {columns.includes('budget_percent_encumbered') && <th>% Encumbered</th>}
        {columns.includes('budget_percent_of_original') && (
          <th>% of Original</th>
        )}
        {columns.includes('requisition_cy') && <th>Requisition</th>}
        {columns.includes('carry_forward_cy') && <th>Carry Forward</th>}
        {columns.includes('carry_forward_transfer_cy') && (
          <th>Carry Forward Transfer</th>
        )}
        {columns.includes('memo_balance_cy') && <th>Memo Balance</th>}
        {columns.includes('actual_po_carry_forward_cy') && (
          <th>Actual PO Carry Forward</th>
        )}
        {columns.includes('estimate_cy') && <th>Estimate</th>}
        {columns.includes('encumb_po_carry_forward_cy') && (
          <th>Encumbrance PO Carry Forward</th>
        )}
        {columns.includes('seg_one') && (
          <th>{municipality?.setting?.seg_one_name || 'Seg One'}</th>
        )}
        {columns.includes('seg_two') && (
          <th>{municipality?.setting?.seg_two_name || 'Seg Two'}</th>
        )}
        {columns.includes('seg_three') && (
          <th>{municipality?.setting?.seg_three_name || 'Seg Three'}</th>
        )}
        {columns.includes('seg_four') && (
          <th>{municipality?.setting?.seg_four_name || 'Seg Four'}</th>
        )}
        {columns.includes('seg_five') && (
          <th>{municipality?.setting?.seg_five_name || 'Seg Five'}</th>
        )}
        {columns.includes('seg_six') && (
          <th>{municipality?.setting?.seg_six_name || 'Seg Six'}</th>
        )}
        {columns.includes('seg_seven') && (
          <th>{municipality?.setting?.seg_seven_name || 'Seg Seven'}</th>
        )}
        {columns.includes('seg_eight') && (
          <th>{municipality?.setting?.seg_eight_name || 'Seg Eight'}</th>
        )}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  tableFilters: PropTypes.shape({
    orderBy: PropTypes.string,
    orderDirection: PropTypes.string
  }),
  setTableFilters: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default TableHeader
