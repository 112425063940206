import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TableFooter from './footer'
import TableLoading from 'shared/tables/table-loading'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import TableFilters from 'transactions/table/filters/index'
import TableHeader from 'transactions/table/header'
import TableRow from 'transactions/table/row'
import useTransactions from 'api/hooks/use-transactions'
import DownloadTransactions from './actions'
import { pickPresentParams } from 'helpers/utils'
import { formatDateParam } from 'helpers/datetime'

const defaultTableFilters = {
  page: 1,
  perPage: 50,
  orderBy: 'transaction_on',
  orderDirection: 'desc',
  external_id: '',
  department_id: '',
  vendors: [],
  excludedVendors: [],
  section_id: '',
  employees: [],
  excludedEmployees: [],
  analytic_ids: [],
  minimumAmount: '',
  maximumAmount: '',
  startOn: '',
  endOn: '',
  unviewedOnly: false,
  open: false,
  inProgress: false,
  exception: false,
  auditReportId: '',
  moleId: '',
  dashboardId: '',
  showCleared: '',
  saved: '',
  orgId: '',
  objectId: '',
  segOne: '',
  segTwo: '',
  segThree: '',
  segFour: '',
  segFive: '',
  orgs: [],
  objects: [],
  projects: [],
  segOnes: [],
  segTwos: [],
  segThrees: [],
  segFours: [],
  segFives: [],
  segSixes: [],
  segSevens: [],
  segEights: [],
  orgsOperator: '',
  objectsOperator: '',
  projectsOperator: '',
  segOnesOperator: '',
  segTwosOperator: '',
  segThreesOperator: '',
  segFoursOperator: '',
  segFivesOperator: '',
  segSixesOperator: '',
  segSevensOperator: '',
  segEightsOperator: '',
  tag: '',
  disableJump: false,
  dateRangeSelected: '',
  flagCount: '',
  poIsPendingWorkflow: '',
  poMinReqToPoCreation: 0,
  poMinPoLiquidatedAmount: 0,
  poMaxPoLiquidatedAmount: '',
  poMinPoRemainingAmount: '',
  poMaxPoRemainingAmount: '',
  poMinPoPercentLiquidated: '',
  poMaxPoPercentLiquidated: '',
  poMinReqPreparedDate: '',
  poMaxReqPreparedDate: '',
  poMinPoEntryDate: '',
  poMaxPoEntryDate: '',
  poMinPoChangeDate: '',
  poMaxPoChangeDate: '',
  poMinPoGlEffectiveDate: '',
  poMaxPoGlEffectiveDate: '',
  poMinPoPostingDate: '',
  poMaxPoPostingDate: '',
  apMinInvoiceToEntryDays: '',
  apMinEntryToPaymentDays: '',
  apMinTotaTotallDaysToPayment: '',
  apMaxTotaTotallDaysToPayment: '',
  apMinInvoiceDate: '',
  apMaxInvoiceDate: '',
  apMinInvoiceDueDate: '',
  apMaxInvoiceDueDate: '',
  apCheckType: '',
  apMinCheckDate: '',
  apMaxCheckDate: '',
  apMinCheckClearDate: '',
  apMaxCheckClearDate: '',
  poStatusCode: '',
  glMinEntryToEffectiveDays: '',
  glMaxEntryToEffectiveDays: '',
  glPeriod: '',
  glMinEntryDate: '',
  glMaxEntryDate: '',
  glMinEffectiveDate: '',
  glMaxEffectiveDate: '',
  glMinPostedDate: '',
  glMaxPostedDate: '',
  voVendorStatus: '',
  voLastInvoiceOnOption: '',
  voMinLastInvoiceOn: '',
  voMaxLastInvoiceOn: '',
  voCurrentYearPoSumOption: '',
  voMinCurrentYearPoSum: '',
  voMaxCurrentYearPoSum: '',
  voIs800Number: '',
  voIsPoBox: '',
  voIsDuplicateEin: '',
  bgAccountType: '',
  bgBudgetYear: '',
  bgMinBudgetPercentOfOriginal: '',
  bgMaxBudgetPercentOfOriginal: '',
  bgMinBudgetPercentRemaining: '',
  bgMaxBudgetPercentRemaining: '',
  bgMinAccountLastUpdate: '',
  bgMaxAccountLastUpdate: ''
}

function TransactionTable({
  sectionId,
  perPage,
  vendors = [],
  excludedVendors = [],
  employees = [],
  excludedEmployees = [],
  minimumAmount = '',
  maximumAmount = '',
  analytic = [],
  orderBy,
  includeOrdering = false,
  columns,
  filtersEnabled = true,
  filters,
  footerEnabled = true,
  auditReportId = '',
  moleId = '',
  dashboardId = '',
  showCleared = '',
  saved = '',
  title = '',
  source = '',
  orgId = '',
  objectId = '',
  segOne = '',
  segTwo = '',
  segThree = '',
  segFour = '',
  segFive = '',
  orgs = [],
  objects = [],
  projects = [],
  segOnes = [],
  segTwos = [],
  segThrees = [],
  segFours = [],
  segFives = [],
  segSixes = [],
  segSevens = [],
  segEights = [],
  orgsOperator = '',
  objectsOperator = '',
  projectsOperator = '',
  segOnesOperator = '',
  segTwosOperator = '',
  segThreesOperator = '',
  segFoursOperator = '',
  segFivesOperator = '',
  segSixesOperator = '',
  segSevensOperator = '',
  segEightsOperator = '',
  tag = '',
  startOn = '',
  endOn = '',
  downloadButton = true,
  defaultParams = {},
  riskScore = '',
  disableJump = false,
  poIsPendingWorkflow = '',
  poMinReqToPoCreation = 0,
  poMinPoLiquidatedAmount = '',
  poMaxPoLiquidatedAmount = '',
  poMinPoRemainingAmount = '',
  poMaxPoRemainingAmount = '',
  poMinPoPercentLiquidated = '',
  poMaxPoPercentLiquidated = '',
  poStatusCode = '',
  poMinReqPreparedDate = '',
  poMaxReqPreparedDate = '',
  poMinPoEntryDate = '',
  poMaxPoEntryDate = '',
  poMinPoChangeDate = '',
  poMaxPoChangeDate = '',
  poMinPoGlEffectiveDate = '',
  poMaxPoGlEffectiveDate = '',
  poMinPoPostingDate = '',
  poMaxPoPostingDate = '',
  apMinEntryToPaymentDays = 0,
  apMinTotalDaysToPayment = 0,
  apMaxTotalDaysToPayment = 0,
  apMinInvoiceToEntryDays = 0,
  apMinInvoiceDate = '',
  apMaxInvoiceDate = '',
  apMinInvoiceDueDate = '',
  apMaxInvoiceDueDate = '',
  apCheckType = '',
  apMinCheckDate = '',
  apMaxCheckDate = '',
  apMinCheckClearDate = '',
  apMaxCheckClearDate = '',
  glMinEntryToEffectiveDays = 0,
  glMaxEntryToEffectiveDays = 0,
  glPeriod = '',
  glMinEntryDate = '',
  glMaxEntryDate = '',
  glMinEffectiveDate = '',
  glMaxEffectiveDate = '',
  glMinPostedDate = '',
  glMaxPostedDate = '',
  voVendorStatus = '',
  voLastInvoiceOnOption = '',
  voMinLastInvoiceOn = '',
  voMaxLastInvoiceOn = '',
  voCurrentYearPoSumOption = '',
  voMinCurrentYearPoSum = '',
  voMaxCurrentYearPoSum = '',
  voIs800Number = '',
  voIsPoBox = '',
  voIsDuplicateEin = '',
  bgAccountType = '',
  bgBudgetYear = '',
  bgMinBudgetPercentOfOriginal = '',
  bgMaxBudgetPercentOfOriginal = '',
  bgMinBudgetPercentRemaining = '',
  bgMaxBudgetPercentRemaining = '',
  bgMinAccountLastUpdate = '',
  bgMaxAccountLastUpdate = ''
}) {
  const [tableFilters, setTableFilters] = useState({
    ...defaultTableFilters,
    analytic_ids: analytic || [],
    section_id: sectionId,
    employees: employees || [],
    excludedEmployees: excludedEmployees || [],
    vendors: vendors || [],
    excludedVendors: excludedVendors || [],
    page: defaultParams.page || 1,
    perPage: perPage || 50,
    orderBy: orderBy || 'transaction_on',
    orderDirection: defaultParams.orderDirection || 'desc',
    startOn: startOn || '',
    endOn: endOn || '',
    saved: saved,
    moleId: moleId,
    dashboardId: dashboardId,
    showCleared: showCleared,
    orgId: orgId,
    objectId: objectId,
    segOne: segOne,
    segTwo: segTwo,
    segThree: segThree,
    segFour: segFour,
    segFive: segFive,
    orgs: orgs,
    objects: objects,
    projects: projects,
    segOnes: segOnes,
    segTwos: segTwos,
    segThrees: segThrees,
    segFours: segFours,
    segFives: segFives,
    segSixes: segSixes,
    segSevens: segSevens,
    segEights: segEights,
    orgsOperator: orgsOperator,
    objectsOperator: objectsOperator,
    projectsOperator: projectsOperator,
    segOnesOperator: segOnesOperator,
    segTwosOperator: segTwosOperator,
    segThreesOperator: segThreesOperator,
    segFoursOperator: segFoursOperator,
    segFivesOperator: segFivesOperator,
    segSixesOperator: segSixesOperator,
    segSevensOperator: segSevensOperator,
    segEightsOperator: segEightsOperator,
    tag: tag,
    auditReportId: auditReportId,
    riskScore: riskScore,
    poIsPendingWorkflow: poIsPendingWorkflow,
    poMinReqToPoCreation: poMinReqToPoCreation,
    poMinPoLiquidatedAmount: poMinPoLiquidatedAmount,
    poMaxPoLiquidatedAmount: poMaxPoLiquidatedAmount,
    poMinPoRemainingAmount: poMinPoRemainingAmount,
    poMaxPoRemainingAmount: poMaxPoRemainingAmount,
    poMinPoPercentLiquidated: poMinPoPercentLiquidated,
    poMaxPoPercentLiquidated: poMaxPoPercentLiquidated,
    poStatusCode: poStatusCode,
    poMinReqPreparedDate: poMinReqPreparedDate,
    poMaxReqPreparedDate: poMaxReqPreparedDate,
    poMinPoEntryDate: poMinPoEntryDate,
    poMaxPoEntryDate: poMaxPoEntryDate,
    poMinPoChangeDate: poMinPoChangeDate,
    poMaxPoChangeDate: poMaxPoChangeDate,
    poMinPoGlEffectiveDate: poMinPoGlEffectiveDate,
    poMaxPoGlEffectiveDate: poMaxPoGlEffectiveDate,
    poMinPoPostingDate: poMinPoPostingDate,
    poMaxPoPostingDate: poMaxPoPostingDate,
    apMinInvoiceDate: apMinInvoiceDate,
    apMaxInvoiceDate: apMaxInvoiceDate,
    apMinInvoiceToEntryDays: apMinInvoiceToEntryDays,
    apMinEntryToPaymentDays: apMinEntryToPaymentDays,
    apMinTotalDaysToPayment: apMinTotalDaysToPayment,
    apMaxTotalDaysToPayment: apMaxTotalDaysToPayment,
    apMinInvoiceDueDate: apMinInvoiceDueDate,
    apMaxInvoiceDueDate: apMaxInvoiceDueDate,
    apCheckType: apCheckType,
    apMinCheckDate: apMinCheckDate,
    apMaxCheckDate: apMaxCheckDate,
    apMinCheckClearDate: apMinCheckClearDate,
    apMaxCheckClearDate: apMaxCheckClearDate,
    glMinEntryToEffectiveDays: glMinEntryToEffectiveDays,
    glMaxEntryToEffectiveDays: glMaxEntryToEffectiveDays,
    glPeriod: glPeriod,
    glMinEntryDate: glMinEntryDate,
    glMaxEntryDate: glMaxEntryDate,
    glMinEffectiveDate: glMinEffectiveDate,
    glMaxEffectiveDate: glMaxEffectiveDate,
    glMinPostedDate: glMinPostedDate,
    glMaxPostedDate: glMaxPostedDate,
    voVendorStatus: voVendorStatus,
    voLastInvoiceOnOption: voLastInvoiceOnOption,
    voMinLastInvoiceOn: voMinLastInvoiceOn,
    voMaxLastInvoiceOn: voMaxLastInvoiceOn,
    voCurrentYearPoSumOption: voCurrentYearPoSumOption,
    voMinCurrentYearPoSum: voMinCurrentYearPoSum,
    voMaxCurrentYearPoSum: voMaxCurrentYearPoSum,
    voIs800Number: voIs800Number,
    voIsPoBox: voIsPoBox,
    voIsDuplicateEin: voIsDuplicateEin,
    bgAccountType: bgAccountType,
    bgBudgetYear: bgBudgetYear,
    bgMinBudgetPercentOfOriginal: bgMinBudgetPercentOfOriginal,
    bgMaxBudgetPercentOfOriginal: bgMaxBudgetPercentOfOriginal,
    bgMinBudgetPercentRemaining: bgMinBudgetPercentRemaining,
    bgMaxBudgetPercentRemaining: bgMaxBudgetPercentRemaining,
    bgMinAccountLastUpdate: bgMinAccountLastUpdate,
    bgMaxAccountLastUpdate: bgMaxAccountLastUpdate
  })
  const tableRef = useRef(null)

  const queryParams = {
    page: tableFilters.page,
    perPage: tableFilters.perPage,
    orderBy: tableFilters.orderBy,
    orderDirection: tableFilters.orderDirection,
    external_id: tableFilters.external_id || '',
    section_id: tableFilters.section_id || '',
    department_id: tableFilters.department_id || '',
    vendors: tableFilters.vendors.map((e) => e.id).join('+') || [],
    excluded_vendors:
      tableFilters.excludedVendors.map((v) => v.id).join('+') || [],
    employees: tableFilters.employees.map((e) => e.id).join('+') || [],
    excluded_employees:
      tableFilters.excludedEmployees.map((e) => e.id).join('+') || [],
    start_on: tableFilters.startOn || '',
    end_on: tableFilters.endOn || '',
    minimum_amount: tableFilters.minimumAmount,
    maximum_amount: tableFilters.maximumAmount,
    analytics: tableFilters.analytic_ids.map((a) => a.id).join('+') || [],
    unviewed: tableFilters.unviewedOnly,
    open: tableFilters.open,
    inprogress: tableFilters.inProgress,
    exception: tableFilters.exception,
    audit_report_id: tableFilters.auditReportId,
    mole_id: tableFilters.moleId,
    dashboard_id: tableFilters.dashboardId,
    show_cleared: tableFilters.showCleared,
    saved: tableFilters.saved,
    org_id: tableFilters.orgId,
    object_id: tableFilters.objectId,
    seg_one_id: tableFilters.segOne?.value || '',
    seg_two_id: tableFilters.segTwo?.value || '',
    seg_three_id: tableFilters.segThree?.value || '',
    seg_four_id: tableFilters.segFour?.value || '',
    seg_five_id: tableFilters.segFive?.value || '',
    org_ids: tableFilters.orgs.join('+') || [],
    object_ids: tableFilters.objects.join('+') || [],
    project_ids: tableFilters.projects.join('+') || [],
    seg_one_ids: tableFilters.segOnes.join('+') || [],
    seg_two_ids: tableFilters.segTwos.join('+') || [],
    seg_three_ids: tableFilters.segThrees.join('+') || [],
    seg_four_ids: tableFilters.segFours.join('+') || [],
    seg_five_ids: tableFilters.segFives.join('+') || [],
    seg_six_ids: tableFilters.segSixes.join('+') || [],
    seg_seven_ids: tableFilters.segSevens.join('+') || [],
    seg_eight_ids: tableFilters.segEights.join('+') || [],
    orgs_operator: tableFilters.orgsOperator,
    objects_operator: tableFilters.objectsOperator,
    projects_operator: tableFilters.projectsOperator,
    seg_ones_operator: tableFilters.segOnesOperator,
    seg_twos_operator: tableFilters.segTwosOperator,
    seg_threes_operator: tableFilters.segThreesOperator,
    seg_fours_operator: tableFilters.segFoursOperator,
    seg_fives_operator: tableFilters.segFivesOperator,
    seg_sixes_operator: tableFilters.segSixesOperator,
    seg_sevens_operator: tableFilters.segSevensOperator,
    seg_eights_operator: tableFilters.segEightsOperator,
    tag_id: tableFilters.tag,
    include_ordering: includeOrdering,
    flag_count: tableFilters.flagCount,
    risk_score: tableFilters.riskScore,
    po_is_pending_workflow: tableFilters.poIsPendingWorkflow,
    po_min_req_to_po_creation: tableFilters.poMinReqToPoCreation,
    po_min_po_liquidated_amount: tableFilters.poMinPoLiquidatedAmount,
    po_max_po_liquidated_amount: tableFilters.poMaxPoLiquidatedAmount,
    po_min_po_remaining_amount: tableFilters.poMinPoRemainingAmount,
    po_max_po_remaining_amount: tableFilters.poMaxPoRemainingAmount,
    po_min_po_percent_liquidated: tableFilters.poMinPoPercentLiquidated,
    po_max_po_percent_liquidated: tableFilters.poMaxPoPercentLiquidated,
    po_po_status_code: tableFilters.poStatusCode,
    po_min_req_prepared_date: formatDateParam(
      tableFilters.poMinReqPreparedDate
    ),
    po_max_req_prepared_date: formatDateParam(
      tableFilters.poMaxReqPreparedDate
    ),
    po_min_po_entry_date: formatDateParam(tableFilters.poMinPoEntryDate),
    po_max_po_entry_date: formatDateParam(tableFilters.poMaxPoEntryDate),
    po_min_po_change_date: formatDateParam(tableFilters.poMinPoChangeDate),
    po_max_po_change_date: formatDateParam(tableFilters.poMaxPoChangeDate),
    po_min_po_gl_effective_date: formatDateParam(
      tableFilters.poMinPoGlEffectiveDate
    ),
    po_max_po_gl_effective_date: formatDateParam(
      tableFilters.poMaxPoGlEffectiveDate
    ),
    po_min_po_posting_date: formatDateParam(tableFilters.poMinPoPostingDate),
    po_max_po_posting_date: formatDateParam(tableFilters.poMaxPoPostingDate),
    ap_min_invoice_to_entry_days: tableFilters.apMinInvoiceToEntryDays,
    ap_min_entry_to_payment_days: tableFilters.apMinEntryToPaymentDays,
    ap_min_total_days_to_payment: tableFilters.apMinTotalDaysToPayment,
    ap_max_total_days_to_payment: tableFilters.apMaxTotalDaysToPayment,
    ap_min_invoice_date: formatDateParam(tableFilters.apMinInvoiceDate),
    ap_max_invoice_date: formatDateParam(tableFilters.apMaxInvoiceDate),
    ap_min_invoice_due_date: formatDateParam(tableFilters.apMinInvoiceDueDate),
    ap_max_invoice_due_date: formatDateParam(tableFilters.apMaxInvoiceDueDate),
    ap_check_type: tableFilters.apCheckType,
    ap_min_check_date: formatDateParam(tableFilters.apMinCheckDate),
    ap_max_check_date: formatDateParam(tableFilters.apMaxCheckDate),
    ap_min_check_clear_date: formatDateParam(tableFilters.apMinCheckClearDate),
    ap_max_check_clear_date: formatDateParam(tableFilters.apMaxCheckClearDate),
    gl_min_entry_to_effective_days: tableFilters.glMinEntryToEffectiveDays,
    gl_max_entry_to_effective_days: tableFilters.glMaxEntryToEffectiveDays,
    gl_period: tableFilters.glPeriod,
    gl_min_entry_date: formatDateParam(tableFilters.glMinEntryDate),
    gl_max_entry_date: formatDateParam(tableFilters.glMaxEntryDate),
    gl_min_effective_date: formatDateParam(tableFilters.glMinEffectiveDate),
    gl_max_effective_date: formatDateParam(tableFilters.glMaxEffectiveDate),
    gl_min_posted_date: formatDateParam(tableFilters.glMinPostedDate),
    gl_max_posted_date: formatDateParam(tableFilters.glMaxPostedDate),
    vo_vendor_status: tableFilters.voVendorStatus,
    vo_last_invoice_on_option: tableFilters.voLastInvoiceOnOption,
    vo_min_last_invoice_on: formatDateParam(tableFilters.voMinLastInvoiceOn),
    vo_max_last_invoice_on: formatDateParam(tableFilters.voMaxLastInvoiceOn),
    vo_current_year_po_sum_option: tableFilters.voCurrentYearPoSumOption,
    vo_min_current_year_po_sum: tableFilters.voMinCurrentYearPoSum,
    vo_max_current_year_po_sum: tableFilters.voMaxCurrentYearPoSum,
    vo_is_800_number: tableFilters.voIs800Number,
    vo_is_po_box: tableFilters.voIsPoBox,
    vo_is_duplicate_ein: tableFilters.voIsDuplicateEin,
    bg_account_type: tableFilters.bgAccountType,
    bg_budget_year: tableFilters.bgBudgetYear,
    bg_min_budget_percent_of_original:
      tableFilters.bgMinBudgetPercentOfOriginal,
    bg_max_budget_percent_of_original:
      tableFilters.bgMaxBudgetPercentOfOriginal,
    bg_min_budget_percent_remaining: tableFilters.bgMinBudgetPercentRemaining,
    bg_max_budget_percent_remaining: tableFilters.bgMaxBudgetPercentRemaining,
    bg_min_account_last_update: formatDateParam(
      tableFilters.bgMinAccountLastUpdate
    ),
    bg_max_account_last_update: formatDateParam(
      tableFilters.bgMaxAccountLastUpdate
    )
  }

  const {
    isSuccess,
    isLoading,
    data: transactions
  } = useTransactions({
    params: pickPresentParams(queryParams)
  })

  const transactionIds = isSuccess ? transactions.data.map((t) => t.id) : []

  const updateTableFilters = (key, value) => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      [key]: value
    }))
  }
  // TODO: This is a hack to get the table to refresh when the filters change on dashboard page
  // This should be easier but having issues...
  // Might be fixed by passing in dashboardId now?

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      endOn: endOn
    }))
  }, [endOn])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      startOn: startOn
    }))
  }, [startOn])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      riskScore: riskScore
    }))
  }, [riskScore])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      vendors: vendors || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      excludedVendors: excludedVendors || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedVendors.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      employees: employees || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      excluded_employees: excludedEmployees || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedEmployees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      section_id: sectionId
    }))
  }, [sectionId])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      minimumAmount: minimumAmount
    }))
  }, [minimumAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      maximumAmount: maximumAmount
    }))
  }, [maximumAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      showCleared: showCleared
    }))
  }, [showCleared])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      orgs: orgs
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      objects: objects
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      projects: projects
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segOnes: segOnes
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segOnes.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segTwos: segTwos
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segTwos.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segThrees: segThrees
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segThrees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFours: segFours
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFours.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFives: segFives
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFives.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSixes: segSixes
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSixes.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSevens: segSevens
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSevens.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segEights: segEights
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segEights.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      orgsOperator: orgsOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgsOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      objectsOperator: objectsOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectsOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      projectsOperator: projectsOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segOnesOperator: segOnesOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segOnesOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segTwosOperator: segTwosOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segTwosOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segThreesOperator: segThreesOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segThreesOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFoursOperator: segFoursOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFoursOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFivesOperator: segFivesOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFivesOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSixesOperator: segSixesOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSixesOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSevensOperator: segSevensOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSevensOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segEightsOperator: segEightsOperator
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segEightsOperator])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      analytic_ids: analytic || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytic.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poIsPendingWorkflow: poIsPendingWorkflow
    }))
  }, [poIsPendingWorkflow])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinReqToPoCreation: poMinReqToPoCreation
    }))
  }, [poMinReqToPoCreation])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoLiquidatedAmount: poMinPoLiquidatedAmount
    }))
  }, [poMinPoLiquidatedAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoLiquidatedAmount: poMaxPoLiquidatedAmount
    }))
  }, [poMaxPoLiquidatedAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoRemainingAmount: poMinPoRemainingAmount
    }))
  }, [poMinPoRemainingAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoRemainingAmount: poMaxPoRemainingAmount
    }))
  }, [poMaxPoRemainingAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoPercentLiquidated: poMinPoPercentLiquidated
    }))
  }, [poMinPoPercentLiquidated])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoPercentLiquidated: poMaxPoPercentLiquidated
    }))
  }, [poMaxPoPercentLiquidated])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poStatusCode: poStatusCode
    }))
  }, [poStatusCode])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinReqPreparedDate: poMinReqPreparedDate
    }))
  }, [poMinReqPreparedDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxReqPreparedDate: poMaxReqPreparedDate
    }))
  }, [poMaxReqPreparedDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoEntryDate: poMinPoEntryDate
    }))
  }, [poMinPoEntryDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoEntryDate: poMaxPoEntryDate
    }))
  }, [poMaxPoEntryDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoChangeDate: poMinPoChangeDate
    }))
  }, [poMinPoChangeDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoChangeDate: poMaxPoChangeDate
    }))
  }, [poMaxPoChangeDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoGlEffectiveDate: poMinPoGlEffectiveDate
    }))
  }, [poMinPoGlEffectiveDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoGlEffectiveDate: poMaxPoGlEffectiveDate
    }))
  }, [poMaxPoGlEffectiveDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinPoPostingDate: poMinPoPostingDate
    }))
  }, [poMinPoPostingDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMaxPoPostingDate: poMaxPoPostingDate
    }))
  }, [poMaxPoPostingDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinEntryToPaymentDays: apMinEntryToPaymentDays
    }))
  }, [apMinEntryToPaymentDays])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinTotalDaysToPayment: apMinTotalDaysToPayment
    }))
  }, [apMinTotalDaysToPayment])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMaxTotalDaysToPayment: apMaxTotalDaysToPayment
    }))
  }, [apMaxTotalDaysToPayment])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinInvoiceToEntryDays: apMinInvoiceToEntryDays
    }))
  }, [apMinInvoiceToEntryDays])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinInvoiceDate: apMinInvoiceDate
    }))
  }, [apMinInvoiceDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMaxInvoiceDate: apMaxInvoiceDate
    }))
  }, [apMaxInvoiceDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinInvoiceDueDate: apMinInvoiceDueDate
    }))
  }, [apMinInvoiceDueDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMaxInvoiceDueDate: apMaxInvoiceDueDate
    }))
  }, [apMaxInvoiceDueDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apCheckType: apCheckType
    }))
  }, [apCheckType])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinCheckDate: apMinCheckDate
    }))
  }, [apMinCheckDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMaxCheckDate: apMaxCheckDate
    }))
  }, [apMaxCheckDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinCheckClearDate: apMinCheckClearDate
    }))
  }, [apMinCheckClearDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMaxCheckClearDate: apMaxCheckClearDate
    }))
  }, [apMaxCheckClearDate])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      glMinEntryToEffectiveDays: glMinEntryToEffectiveDays
    }))
  }, [glMinEntryToEffectiveDays])

  useEffect(() => {
    updateTableFilters('glMaxEntryToEffectiveDays', glMaxEntryToEffectiveDays)
  }, [glMaxEntryToEffectiveDays])

  useEffect(() => {
    updateTableFilters('glPeriod', glPeriod)
  }, [glPeriod])

  useEffect(() => {
    updateTableFilters('glMinEntryDate', glMinEntryDate)
  }, [glMinEntryDate])

  useEffect(() => {
    updateTableFilters('glMaxEntryDate', glMaxEntryDate)
  }, [glMaxEntryDate])

  useEffect(() => {
    updateTableFilters('glMinEffectiveDate', glMinEffectiveDate)
  }, [glMinEffectiveDate])

  useEffect(() => {
    updateTableFilters('glMaxEffectiveDate', glMaxEffectiveDate)
  }, [glMaxEffectiveDate])

  useEffect(() => {
    updateTableFilters('glMinPostedDate', glMinPostedDate)
  }, [glMinPostedDate])

  useEffect(() => {
    updateTableFilters('glMaxPostedDate', glMaxPostedDate)
  }, [glMaxPostedDate])

  useEffect(() => {
    updateTableFilters('voVendorStatus', voVendorStatus)
  }, [voVendorStatus])

  useEffect(() => {
    updateTableFilters('voLastInvoiceOnOption', voLastInvoiceOnOption)
  }, [voLastInvoiceOnOption])

  useEffect(() => {
    updateTableFilters('voMinLastInvoiceOn', voMinLastInvoiceOn)
  }, [voMinLastInvoiceOn])

  useEffect(() => {
    updateTableFilters('voMaxLastInvoiceOn', voMaxLastInvoiceOn)
  }, [voMaxLastInvoiceOn])

  useEffect(() => {
    updateTableFilters('voCurrentYearPoSumOption', voCurrentYearPoSumOption)
  }, [voCurrentYearPoSumOption])

  useEffect(() => {
    updateTableFilters('voMinCurrentYearPoSum', voMinCurrentYearPoSum)
  }, [voMinCurrentYearPoSum])

  useEffect(() => {
    updateTableFilters('voMaxCurrentYearPoSum', voMaxCurrentYearPoSum)
  }, [voMaxCurrentYearPoSum])

  useEffect(() => {
    updateTableFilters('voIs800Number', voIs800Number)
  }, [voIs800Number])

  useEffect(() => {
    updateTableFilters('voIsPoBox', voIsPoBox)
  }, [voIsPoBox])

  useEffect(() => {
    updateTableFilters('voIsDuplicateEin', voIsDuplicateEin)
  }, [voIsDuplicateEin])

  useEffect(() => {
    updateTableFilters('bgAccountType', bgAccountType)
  }, [bgAccountType])

  useEffect(() => {
    updateTableFilters('bgBudgetYear', bgBudgetYear)
  }, [bgBudgetYear])

  useEffect(() => {
    updateTableFilters(
      'bgMinBudgetPercentOfOriginal',
      bgMinBudgetPercentOfOriginal
    )
  }, [bgMinBudgetPercentOfOriginal])

  useEffect(() => {
    updateTableFilters(
      'bgMaxBudgetPercentOfOriginal',
      bgMaxBudgetPercentOfOriginal
    )
  }, [bgMaxBudgetPercentOfOriginal])

  useEffect(() => {
    updateTableFilters(
      'bgMinBudgetPercentRemaining',
      bgMinBudgetPercentRemaining
    )
  }, [bgMinBudgetPercentRemaining])

  useEffect(() => {
    updateTableFilters(
      'bgMaxBudgetPercentRemaining',
      bgMaxBudgetPercentRemaining
    )
  }, [bgMaxBudgetPercentRemaining])

  useEffect(() => {
    updateTableFilters('bgMinAccountLastUpdate', bgMinAccountLastUpdate)
  }, [bgMinAccountLastUpdate])

  useEffect(() => {
    updateTableFilters('bgMaxAccountLastUpdate', bgMaxAccountLastUpdate)
  }, [bgMaxAccountLastUpdate])

  return (
    <div ref={tableRef}>
      {title.length > 0 && (
        <Row className="my-2">
          <Col>
            {' '}
            <h4>{title}</h4>
          </Col>
          <hr />
        </Row>
      )}
      {(filtersEnabled || downloadButton) && (
        <Card className="mb-4">
          <Card.Body>
            <Row className="g-2">
              {filtersEnabled && (
                <TableFilters
                  filters={filters}
                  tableFilters={tableFilters}
                  setTableFilters={setTableFilters}
                  tableRef={tableRef}
                  defaultTableFilters={defaultTableFilters}
                />
              )}
              <Col sm="auto">
                {downloadButton && (
                  <DownloadTransactions
                    defaultName={`${source} Transactions`}
                    source={source}
                    tableFilters={tableFilters}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      <div className="table-responsive">
        <table
          className="bg-white shadow-sm table mb-0 text-nowrap"
          style={{ border: '1px solid rgb(222 226 230)' }}
        >
          <TableHeader
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            columns={columns}
            includeOrdering={includeOrdering}
          />
          <tbody>
            {isLoading && <TableLoading columns={7} />}
            {isSuccess && transactions.data.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <div className="my-4">No transactions found</div>
                </td>
              </tr>
            )}
            {isSuccess &&
              transactions.data.map((t, i) => (
                <TableRow
                  transaction={t}
                  key={`row-${t.id}-${i}`}
                  columns={columns}
                  auditReportId={auditReportId}
                  moleId={moleId}
                  transactions={transactionIds}
                  dashboardId={dashboardId}
                />
              ))}
          </tbody>
        </table>
      </div>

      <Row className="mt-4">
        <Col>
          {isSuccess && footerEnabled && (
            <TableFooter
              meta={transactions.meta}
              params={tableFilters}
              setParams={setTableFilters}
              disableJump={disableJump}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

TransactionTable.propTypes = {
  section: PropTypes.number,
  vendors: PropTypes.array,
  employees: PropTypes.array,
  analytics: PropTypes.array,
  columns: PropTypes.array.isRequired,
  filtersEnabled: PropTypes.bool,
  auditReportId: PropTypes.string,
  moleId: PropTypes.string
}

export default TransactionTable
