import React from 'react'
import DateField from 'shared/forms/DateField'

function PoEntryDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="PO Entry Date"
      showTitle={true}
      accessorKey="PoEntryDate"
      sectionAbbreviation="po"
    />
  )
}

export default PoEntryDate
