import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useAccounts } from 'api/hooks/use-accounts'
import Table from 'react-bootstrap/Table'
import Search from 'shared/forms/search'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { isNull } from 'lodash'
import TableLoading from 'shared/tables/table-loading'
import FilterAccountCode from './filter-account-code'
import TableFooter from './table-footer'
import smallDot from 'shared/small-dot.svg'
import { useCreateDashboardAccountSearches } from 'api/hooks/use-dashboard-account-searches'
import UserContext from 'context/user-context'
import FilteredBadge from './filtered-badge'

const AccountColumn = ({ account }) => {
  if (isNull(account)) {
    return <></>
  }
  return (
    <div className="ms-1">
      {account.code}
      <span className="fw-light text-muted mx-2 text-nowrap">
        {account.name}
      </span>
    </div>
  )
}

function AccountsFilter({
  params,
  setParams,
  setIsFilterOpen,
  appPage = 'Dashboard',
  withTitle = true,
  buttonSize = 'sm'
}) {
  const [show, setShow] = useState(false)
  const [activeOverlay, setActiveOverlay] = useState(null)
  const { municipality } = useContext(UserContext)

  const {
    seg_one_name,
    seg_two_name,
    seg_three_name,
    seg_four_name,
    seg_five_name,
    seg_six_name,
    seg_seven_name,
    seg_eight_name
  } = municipality.setting

  const accountParams = {
    search: '',
    page: 1,
    dashboard_id: params?.dashboardId || '',
    perPage: 20,
    org: params.orgs || [],
    orgs_operator: params.orgsOperator || '',
    org_search: '',
    object: params.objects || [],
    objects_operator: params.objectsOperator || '',
    object_search: '',
    seg_one: params.segOnes || [],
    seg_ones_operator: params.segOnesOperator || '',
    seg_one_search: '',
    seg_two: params.segTwos || [],
    seg_twos_operator: params.segTwosOperator || '',
    seg_two_search: '',
    seg_three: params.segThrees || [],
    seg_threes_operator: params.segThreesOperator || '',
    seg_three_search: '',
    seg_four: params.segFours || [],
    seg_fours_operator: params.segFoursOperator || '',
    seg_four_search: '',
    seg_five: params.segFives || [],
    seg_fives_operator: params.segFivesOperator || '',
    seg_five_search: '',
    seg_six: params.segSixes || [],
    seg_sixes_operator: params.segSixesOperator || '',
    seg_six_search: '',
    seg_seven: params.segSevens || [],
    seg_sevens_operator: params.segSevensOperator || '',
    seg_seven_search: '',
    seg_eight: params.segEights || [],
    seg_eights_operator: params.segEightsOperator || '',
    seg_eight_search: '',
    project: params.projects || [],
    projects_operator: params.projectsOperator || '',
    project_search: ''
  }
  // TODO: Change to only mutate on the dashboard
  const { mutate } = useCreateDashboardAccountSearches(
    {
      params: accountParams
    },
    { enabled: appPage === 'Dashboard' }
  )

  const handleSave = () => {
    mutate(params)
    handleClose()
  }
  const handleClose = () => {
    setIsFilterOpen(false)
    setShow(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }
  const options = {
    keepPreviousData: true
  }
  const { data, isSuccess, isLoading, isFetching } = useAccounts({
    params: accountParams,
    options
  })
  const isOrgFiltered = params.orgs.length > 0
  const isObjectFiltered = params.objects.length > 0
  const isSegOneFiltered = params.segOnes.length > 0
  const isSegTwoFiltered = params.segTwos.length > 0
  const isSegThreeFiltered = params.segThrees.length > 0
  const isSegFourFiltered = params.segFours.length > 0
  const isSegFiveFiltered = params.segFives.length > 0
  const isSegSixFiltered = params.segSixes.length > 0
  const isSegSevenFiltered = params.segSevens.length > 0
  const isSegEightFiltered = params.segEights.length > 0
  const isProjectFiltered = params.projects.length > 0
  const isFiltered =
    isOrgFiltered ||
    isObjectFiltered ||
    isSegOneFiltered ||
    isSegTwoFiltered ||
    isSegThreeFiltered ||
    isSegFourFiltered ||
    isSegFiveFiltered ||
    isSegSixFiltered ||
    isSegSevenFiltered ||
    isSegEightFiltered ||
    isProjectFiltered

  return (
    <React.Fragment>
      {withTitle && (
        <label className="mr-2 fw-light text-muted sr-only">
          <small>Accounts</small>
        </label>
      )}
      <div>
        <Button
          onClick={handleShow}
          size={buttonSize}
          className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
          style={{ border: isFiltered ? '' : '1px solid #ccc' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
        >
          {isFiltered && (
            <img
              alt="small dot"
              src={smallDot}
              style={{ height: '10px', width: '10px' }}
            />
          )}
          Accounts
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="xl" enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Accounts Filter{' '}
            <Button
              variant="primary"
              size="sm"
              className="ms-2"
              onClick={appPage === 'Dashboard' ? handleSave : handleClose}
            >
              Filter for {data?.meta?.total} Accounts
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* TODO: Enable when ready */}
          {false && (
            <Row>
              <Col>
                <Search
                  placeholder="Search by ID, Name or Description"
                  className="mb-4"
                  params={params}
                  setParams={setParams}
                />
              </Col>
            </Row>
          )}

          <Row className="mt-2">
            <Col sm={12}>
              {/* Badges of the current filters */}
              {isFiltered &&
                [
                  'orgs',
                  'objects',
                  'projects',
                  'segOnes',
                  'segTwos',
                  'segThrees',
                  'segFours',
                  'segFives',
                  'segSixes',
                  'segSevens',
                  'segEights'
                ].map((codeType) => {
                  const isFiltered = params[codeType].length > 0

                  return (
                    isFiltered && (
                      <FilteredBadge key={`badge-${codeType}`} codeType={codeType} params={params} setParams={setParams} />
                    )
                  )
                })}
            </Col>
          </Row>

          <Table bordered responsive size="sm" className="mt-4">
            <thead>
              <tr>
                <th>Full Account</th>
                <th>
                  <span className="me-2">Org</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="orgs"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">Object</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="objects"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">Project</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="projects"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_one_name || 'Seg One'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segOnes"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_two_name || 'Seg Two'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segTwos"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_three_name || 'Seg Three'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segThrees"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_four_name || 'Seg Four'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segFours"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_five_name || 'Seg Five'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segFives"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_six_name || 'Seg Six'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segSixes"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_seven_name || 'Seg Seven'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segSevens"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
                <th>
                  <span className="me-2">{seg_eight_name || 'Seg Eight'}</span>
                  <FilterAccountCode
                    params={params}
                    setParams={setParams}
                    codeType="segEights"
                    activeOverlay={activeOverlay}
                    setActiveOverlay={setActiveOverlay}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <TableLoading columns={12} />}

              {isSuccess && !isFetching && data.data.length === 0 && (
                <tr>
                  <td colSpan={12} className="text-center">
                    No accounts found
                  </td>
                </tr>
              )}

              {isSuccess &&
                data.data.map((account) => (
                  <tr key={`account-${account.id}`} className="py-1">
                    <td>
                      <span className="fw-light text-muted ms-2 text-nowrap">
                        {account.full_account}
                      </span>
                    </td>
                    <td>
                      <AccountColumn account={account.org} />
                    </td>
                    <td>
                      <AccountColumn account={account.object} />
                    </td>
                    <td>
                      <AccountColumn account={account.project} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_one} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_two} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_three} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_four} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_five} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_six} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_seven} />
                    </td>
                    <td>
                      <AccountColumn account={account.seg_eight} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {isSuccess && !isFetching && (
            <TableFooter
              meta={data.meta}
              params={params}
              setParams={setParams}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="mb-4">
          <Button
            variant="primary"
            onClick={appPage === 'Dashboard' ? handleSave : handleClose}
          >
            Filter for {data?.meta?.total} Accounts
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default AccountsFilter
