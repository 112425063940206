import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import { formatDateSlash } from 'helpers/datetime'
import QuickLookDrawer from 'transactions/drawer'
import ProcessingAuditReportDrawer from 'transactions/processing-audit-report-drawer'
import ProcessingMoleDrawer from 'transactions/processing-mole-drawer'
import Button from 'react-bootstrap/Button'
import { displayPercent, twoDigitMoney } from 'helpers/utils'
import FlagCount from 'shared/flag-count'
import DestroyBookmark from 'shared/destroy-bookmark'
import CreateBookmark from 'shared/create-bookmark'
import MultiReviewStatusColumn from './multi-review-status-column'
import ReviewStatusColumn from './review-status-column'
import NewReviewBadge from 'shared/badges/new-review-badge'
import VendorChange from 'shared/vendor-change-badge'
import TestingStatus from './testing-status'
import { useNavigate } from 'react-router-dom'
import RiskBadge from 'shared/badges/risk-badge'
import { truncate, isNull } from 'lodash'
import TextCell from './cells/TextCell'
import DateCell from './cells/DateCell'
import MoneyCell from './cells/MoneyCell'
import BooleanCell from './cells/BooleanCell'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBook,
  faShoppingCart,
  faCreditCard,
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons'

function TableRow({
  transaction,
  transactions,
  columns,
  auditReportId,
  moleId,
  dashboardId
}) {
  const [showQuickLook, setShowQuickLook] = useState(false)
  const [showProcessing, setShowProcessing] = useState(false)
  const navigate = useNavigate()

  const isVendorChange = transaction.section_name === 'Vendor Changes'

  const displayKey = (transaction) => {
    if (
      transaction.section_name === 'PCard' ||
      transaction.section_name === 'General Ledger'
    ) {
      return truncate(transaction.display_key, { length: 15 })
    } else if (transaction.section_name === 'Budget') {
      return transaction.display_key
    } else if (transaction.section_name === 'Purchasing') {
      return isNull(transaction.display_key_two)
        ? truncate(`RQ: ${transaction.display_key}`, { length: 15 })
        : truncate(`PO: ${transaction.display_key_two}`, { length: 15 })
    } else {
      return truncate(transaction.display_key_two, { length: 15 })
    }
  }

  return (
    <tr className={isVendorChange ? 'table-light' : ''}>
      {columns.includes('cleared') && (
        <td className="align-middle">
          {transaction.section_name === 'Vendor Changes' ? (
            <VendorChange />
          ) : transaction.section_name === 'General Ledger' ? (
            <Badge pill className="bg-light text-primary mx-1" variant="light">
              <FontAwesomeIcon icon={faBook} className="text-primary" />{' '}
              {transaction.display_key}-{transaction.display_key_two}
            </Badge>
          ) : transaction.section_name === 'PCard' ? (
            <Badge pill className="bg-light text-primary mx-1" variant="light">
              <FontAwesomeIcon icon={faCreditCard} className="text-primary" />{' '}
              {transaction.display_key}
            </Badge>
          ) : transaction.section_name === 'Purchasing' ? (
            <Badge pill className="bg-light text-primary mx-1" variant="light">
              <FontAwesomeIcon icon={faShoppingCart} className="text-primary" />{' '}
              {transaction.display_key}
            </Badge>
          ) : transaction.section_name === 'Accounts Payable' ? (
            <Badge pill className="bg-light text-primary mx-1" variant="light">
              <FontAwesomeIcon icon={faFileInvoice} className="text-primary" />{' '}
              {transaction.display_key_two}
            </Badge>
          ) : null}
        </td>
      )}
      {columns.includes('save') && (
        <td className="align-middle">
          <div className="ms-2">
            {transaction.saved_transaction?.id ? (
              <DestroyBookmark transaction={transaction} />
            ) : (
              <CreateBookmark transaction={transaction} />
            )}
          </div>
        </td>
      )}
      {columns.includes('id') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowQuickLook(true)}>
            {transaction.section_name === 'PCard'
              ? transaction.display_key
              : transaction.section_display_key + ' ' + transaction.display_key}
          </Button>
        </td>
      )}
      {columns.includes('view') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowQuickLook(true)}>
            {displayKey(transaction)}
          </Button>
        </td>
      )}
      {columns.includes('processing_modal') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowProcessing(true)}>
            {transaction.external_id}
          </Button>
          {transaction?.is_viewed === false && (
            <NewReviewBadge showCount={false} />
          )}
        </td>
      )}
      {columns.includes('risk_score') && (
        <td className="text-center align-middle">
          <RiskBadge count={transaction.risk_score} showCount={true} />
        </td>
      )}
      {columns.includes('analytics_count') && (
        <td className="text-center align-middle">
          <FlagCount count={transaction.analytics_count} />
        </td>
      )}
      {columns.includes('report_analytics_count') && (
        <td className="text-center align-middle">
          <FlagCount count={transaction.report_analytics_count} />
        </td>
      )}
      {columns.includes('status') && (
        <td className="align-middle text-center">
          <MultiReviewStatusColumn reviewSummary={transaction.review_summary} />
        </td>
      )}
      {columns.includes('single-status') && (
        <td className="align-middle text-center">
          <ReviewStatusColumn transaction={transaction} />
        </td>
      )}
      {columns.includes('testing-status') && (
        <td className="align-middle text-center">
          <TestingStatus featureSummary={transaction.feature_summary} />
        </td>
      )}
      {columns.includes('transaction_on') && (
        <td className="align-middle text-muted">
          {formatDateSlash(transaction.transaction_on)}
        </td>
      )}
      {columns.includes('line_amount') && (
        <td className="align-middle">
          {transaction.section_name === 'Vendor Changes' ? (
            <VendorChange />
          ) : (
            twoDigitMoney(transaction.line_amount)
          )}
        </td>
      )}
      {columns.includes('vendor') && (
        <td className="align-middle text-muted fw-light">
          <a
            href={`/vendors/${transaction.vendor_id}`}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/vendors/${transaction.vendor_id}`)
            }}
          >
            {truncate(transaction.vendor_name, { length: 20 })}
          </a>
        </td>
      )}
      {columns.includes('vendor_name') && (
        <td className="align-middle text-muted fw-light">
          {transaction.vendor_name}
        </td>
      )}
      {columns.includes('employee') && (
        <td className="align-middle text-muted fw-light">
          <a
            href={`/employees/${transaction.employee_id}`}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/employees/${transaction.employee_id}`)
            }}
          >
            {transaction.employee_external_id}
          </a>
        </td>
      )}
      {columns.includes('employee_name') && (
        <td className="align-middle text-muted fw-light">
          {transaction.employee_external_id}
        </td>
      )}
      {showQuickLook && (
        <QuickLookDrawer
          transactions={transactions}
          transactionId={transaction.id}
          show={showQuickLook}
          setShow={setShowQuickLook}
          dashboardId={dashboardId}
        />
      )}
      {showProcessing && (
        <React.Fragment>
          {auditReportId ? (
            <ProcessingAuditReportDrawer
              transactions={transactions}
              transactionId={transaction.id}
              externalId={transaction.external_id}
              auditReportId={auditReportId}
              show={showProcessing}
              setShow={setShowProcessing}
            />
          ) : (
            <ProcessingMoleDrawer
              transactions={transactions}
              transactionId={transaction.id}
              externalId={transaction.external_id}
              moleId={moleId}
              show={showProcessing}
              setShow={setShowProcessing}
            />
          )}
        </React.Fragment>
      )}
      {/* ap_transaction_details */}
      {columns.includes('vendor_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.vendor_type}
        </td>
      )}
      {columns.includes('vendor_external_id') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.vendor_external_id}
        </td>
      )}
      {columns.includes('vendor_minority_code') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.vendor_minority_code}
        </td>
      )}
      {columns.includes('invoice_number') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_number}
        </td>
      )}
      {columns.includes('invoice_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.invoice_amount)}
        </td>
      )}
      {columns.includes('invoice_to_entry_days') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_to_entry_days}
        </td>
      )}
      {columns.includes('entry_to_payment_days') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.entry_to_payment_days}
        </td>
      )}
      {columns.includes('total_days_to_payment') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.total_days_to_payment}
        </td>
      )}
      {columns.includes('po_number') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.po_number}
        </td>
      )}
      {columns.includes('po_entry_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.po_entry_date)}
        </td>
      )}
      {columns.includes('invoice_entered_by') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_entered_by}
        </td>
      )}
      {columns.includes('invoice_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.invoice_date)}
        </td>
      )}
      {columns.includes('invoice_due_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.invoice_due_date)}
        </td>
      )}
      {columns.includes('invoice_gl_effective_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(
            transaction?.transaction_details?.invoice_gl_effective_date
          )}
        </td>
      )}
      {columns.includes('invoice_department_code') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_department_code}
        </td>
      )}
      {columns.includes('invoice_status') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_status_code}
        </td>
      )}
      {columns.includes('invoice_description') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.invoice_description}
        </td>
      )}
      {columns.includes('check_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.check_amount)}
        </td>
      )}
      {columns.includes('check_void_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.check_void_amount)}
        </td>
      )}
      {columns.includes('check_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.check_type}
        </td>
      )}
      {columns.includes('check_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.check_date)}
        </td>
      )}
      {columns.includes('check_clear_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.check_clear_date)}
        </td>
      )}
      {columns.includes('check_date_to_clear_days') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.check_date_to_clear_days}
        </td>
      )}
      {columns.includes('full_account') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.full_account}
        </td>
      )}
      {columns.includes('year') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.year}
        </td>
      )}
      {columns.includes('period') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.period}
        </td>
      )}
      {columns.includes('entry_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.entry_date)}
        </td>
      )}
      {columns.includes('effective_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.effective_date)}
        </td>
      )}
      {columns.includes('posted_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.posted_date)}
        </td>
      )}
      {columns.includes('entry_to_effective_days') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.entry_to_effective_days}
        </td>
      )}
      {columns.includes('debit_credit') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.debit_credit}
        </td>
      )}
      {columns.includes('debit') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.debit)}
        </td>
      )}
      {columns.includes('credit') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.credit)}
        </td>
      )}
      {columns.includes('comment') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.comment}
        </td>
      )}
      {columns.includes('journal_number') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.journal_number}
        </td>
      )}
      {columns.includes('statement_id') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.statement_id}
        </td>
      )}
      {columns.includes('doc_number') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.doc_number}
        </td>
      )}
      {columns.includes('card_limit') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.card_limit)}
        </td>
      )}
      {columns.includes('card_number') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.card_number}
        </td>
      )}
      {columns.includes('card_single_purchase_limit') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.card_single_purchase_limit
          )}
        </td>
      )}
      {columns.includes('vendor_address') && (
        <TextCell value={transaction?.transaction_details?.vendor_address} />
      )}
      {columns.includes('vendor_phone_number') && (
        <TextCell
          value={transaction?.transaction_details?.vendor_phone_number1}
        />
      )}
      {columns.includes('vendor_status') && (
        <TextCell value={transaction?.transaction_details?.vendor_status} />
      )}
      {columns.includes('vendor_type') && (
        <TextCell value={transaction?.transaction_details?.vendor_type} />
      )}
      {columns.includes('address') && (
        <TextCell value={transaction?.transaction_details?.address} />
      )}
      {columns.includes('city') && (
        <TextCell value={transaction?.transaction_details?.city} />
      )}
      {columns.includes('state') && (
        <TextCell value={transaction?.transaction_details?.state} />
      )}
      {columns.includes('zip') && (
        <TextCell value={transaction?.transaction_details?.zip} />
      )}
      {columns.includes('created_on') && (
        <DateCell value={transaction?.transaction_details?.created_on} />
      )}
      {columns.includes('created_by_id') && (
        <TextCell>{transaction?.transaction_details?.created_by}</TextCell>
      )}
      {columns.includes('last_invoice_on') && (
        <DateCell value={transaction?.transaction_details?.last_invoice_on} />
      )}
      {columns.includes('last_check_on') && (
        <DateCell value={transaction?.transaction_details?.last_check_on} />
      )}
      {columns.includes('previous_year_po_sum') && (
        <MoneyCell
          value={transaction?.transaction_details?.previous_year_po_sum}
        />
      )}
      {columns.includes('current_year_po_sum') && (
        <MoneyCell
          value={transaction?.transaction_details?.current_year_po_sum}
        />
      )}
      {columns.includes('past_month_po_sum') && (
        <MoneyCell
          value={transaction?.transaction_details?.past_month_po_sum}
        />
      )}
      {columns.includes('past_week_po_sum') && (
        <MoneyCell
          value={transaction?.transaction_details?.past_week_po_sum}
        />
      )}
      {columns.includes('current_year_po_count') && (
        <TextCell value={transaction?.transaction_details?.current_year_po_count} />
      )}
      {columns.includes('current_year_contract_count') && (
        <TextCell value={transaction?.transaction_details?.current_year_contract_count} />
      )}
      {columns.includes('current_year_contracts') && (
        <TextCell value={transaction?.transaction_details?.current_year_contracts} />
      )}
      {columns.includes('is_800_number') && (
        <BooleanCell value={transaction?.transaction_details?.is_800_number} />
      )}
      {columns.includes('is_po_box') && (
        <BooleanCell value={transaction?.transaction_details?.is_po_box} />
      )}
      {columns.includes('is_duplicate_ein') && (
        <BooleanCell
          value={transaction?.transaction_details?.is_duplicate_ein}
        />
      )}
      {columns.includes('minority_code') && (
        <td>{transaction?.transaction_details?.minority_code}</td>
      )}
      {/* po_transaction_details */}
      {columns.includes('req_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.req_type}
        </td>
      )}
      {columns.includes('req_to_po_creation') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.req_to_po_creation}
        </td>
      )}
      {columns.includes('req_prepared_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.req_prepared_date)}
        </td>
      )}
      {columns.includes('req_buyer_id') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.buyer_full_name}
        </td>
      )}
      {columns.includes('req_entered_by_id') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.entered_by_full_name}
        </td>
      )}
      {columns.includes('req_total_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.req_total_amount || ''
          )}
        </td>
      )}
      {columns.includes('req_general_comment') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.req_general_comment}
        </td>
      )}
      {columns.includes('po_department_code') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.po_department_code}
        </td>
      )}
      {columns.includes('po_entry_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.po_entry_date)}
        </td>
      )}
      {columns.includes('po_change_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(transaction?.transaction_details?.po_change_date)}
        </td>
      )}
      {columns.includes('po_gl_effective_date') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(
            transaction?.transaction_details?.po_gl_effective_date
          )}
        </td>
      )}
      {columns.includes('po_status_code') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.po_status}
        </td>
      )}
      {columns.includes('po_ship_to_address') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.po_ship_to_address}
        </td>
      )}
      {columns.includes('po_liquidated_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.po_liquidated_amount
          )}
        </td>
      )}
      {columns.includes('po_remaining_amount') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.po_remaining_amount)}
        </td>
      )}
      {columns.includes('po_percent_liquidated') && (
        <td className="align-middle text-muted fw-light">
          {displayPercent(
            transaction?.transaction_details?.po_percent_liquidated
          )}
        </td>
      )}
      {columns.includes('is_pending_workflow') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.is_pending_workflow ? 'Yes' : 'No'}
        </td>
      )}
      {/* Budget Columns */}
      {columns.includes('org') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.org_name} (
          {transaction?.transaction_details?.org_code})
        </td>
      )}
      {columns.includes('object') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.object_name} (
          {transaction?.transaction_details?.object_code})
        </td>
      )}
      {columns.includes('project') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.project_name}
          {transaction?.transaction_details?.project_code
            ? ` (${transaction?.transaction_details?.project_code})`
            : ''}
        </td>
      )}
      {columns.includes('budget_year') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.budget_year}
        </td>
      )}
      {columns.includes('account_status') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.status}
        </td>
      )}
      {columns.includes('account_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.account_type}
        </td>
      )}
      {columns.includes('balance_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.balance_type}
        </td>
      )}
      {columns.includes('account_last_update') && (
        <td className="align-middle text-muted fw-light">
          {formatDateSlash(
            transaction?.transaction_details?.account_last_update
          )}
        </td>
      )}
      {columns.includes('normal_balance_type') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.normal_balance_type}
        </td>
      )}
      {columns.includes('original_budget_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.original_budget_cy)}
        </td>
      )}
      {columns.includes('transfer_in_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.transfer_in_cy)}
        </td>
      )}
      {columns.includes('transfer_out_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.transfer_out_cy)}
        </td>
      )}
      {columns.includes('revised_py') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.revised_py)}
        </td>
      )}
      {columns.includes('revised_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.revised_cy)}
        </td>
      )}
      {columns.includes('actuals_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.actuals_cy)}
        </td>
      )}
      {columns.includes('encumbrance_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.encumbrance_cy)}
        </td>
      )}
      {columns.includes('budget_amount_remaining') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.budget_amount_remaining
          )}
        </td>
      )}
      {columns.includes('budget_percent_remaining') && (
        <td className="align-middle text-muted fw-light">
          {displayPercent(
            transaction?.transaction_details?.budget_percent_remaining
          )}
        </td>
      )}
      {columns.includes('budget_percent_encumbered') && (
        <td className="align-middle text-muted fw-light">
          {displayPercent(
            transaction?.transaction_details?.budget_percent_encumbered
          )}
        </td>
      )}
      {columns.includes('budget_percent_of_original') && (
        <td className="align-middle text-muted fw-light">
          {displayPercent(
            transaction?.transaction_details?.budget_percent_of_original
          )}
        </td>
      )}
      {columns.includes('requisition_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.requisition_cy)}
        </td>
      )}
      {columns.includes('carry_forward_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.carry_forward_cy)}
        </td>
      )}
      {columns.includes('carry_forward_transfer_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.carry_forward_transfer_cy
          )}
        </td>
      )}
      {columns.includes('memo_balance_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.memo_balance_cy)}
        </td>
      )}
      {columns.includes('actual_po_carry_forward_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.actual_po_carry_forward_cy
          )}
        </td>
      )}
      {columns.includes('estimate_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(transaction?.transaction_details?.estimate_cy)}
        </td>
      )}
      {columns.includes('encumb_po_carry_forward_cy') && (
        <td className="align-middle text-muted fw-light">
          {twoDigitMoney(
            transaction?.transaction_details?.encumb_po_carry_forward_cy
          )}
        </td>
      )}
      {columns.includes('seg_one') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_one_name} (
          {transaction?.transaction_details?.seg_one_code})
        </td>
      )}
      {columns.includes('seg_two') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_two_name} (
          {transaction?.transaction_details?.seg_two_code})
        </td>
      )}
      {columns.includes('seg_three') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_three_name} (
          {transaction?.transaction_details?.seg_three_code})
        </td>
      )}
      {columns.includes('seg_four') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_four_name} (
          {transaction?.transaction_details?.seg_four_code})
        </td>
      )}
      {columns.includes('seg_five') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_five_name} (
          {transaction?.transaction_details?.seg_five_code})
        </td>
      )}
      {columns.includes('seg_six') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_six_name} (
          {transaction?.transaction_details?.seg_six_code})
        </td>
      )}
      {columns.includes('seg_seven') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_seven_name} (
          {transaction?.transaction_details?.seg_seven_code})
        </td>
      )}
      {columns.includes('seg_eight') && (
        <td className="align-middle text-muted fw-light">
          {transaction?.transaction_details?.seg_eight_name} (
          {transaction?.transaction_details?.seg_eight_code})
        </td>
      )}
    </tr>
  )
}

TableRow.propTypes = {
  transaction: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
}

export default TableRow
