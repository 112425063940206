import React from 'react'
import SelectField from 'shared/forms/SelectField'

function AccountType({ params, setParams }) {
  const accountTypes = [
    { value: '', label: 'All' },
    { value: 'E', label: 'Expense' },
    { value: 'R', label: 'Revenue' },
    { value: 'B', label: 'Balance' }
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={accountTypes}
      accessorKey="bgAccountType"
      title="Account Type"
      showTitle={false}
      placeholder="Select Account Type"
    />
  )
}

export default AccountType
