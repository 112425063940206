import React from 'react'
import SelectField from 'shared/forms/SelectField'

function VendorStatus({ params, setParams }) {
  const codes = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'TEMPORARY', label: 'TEMPORARY' },
    { value: 'STOP', label: 'STOP' },
    { value: 'SELF SERVICE', label: 'SELF SERVICE' },
    { value: 'ONE TIME PAY', label: 'ONE TIME PAY' }
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={codes}
      accessorKey="voVendorStatus"
      title="Status"
      showTitle={true}
      placeholder="Select Vendor Status"
    />
  )
}

export default VendorStatus
