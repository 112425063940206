import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ModulePicker from 'reports/form/module-picker'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Shield from 'assets/Shield/Gold/ThirdLineShieldGold.svg'
import { useCreateDashboard } from 'api/hooks/use-dashboards'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

function NewView({ sectionId = '', sectionName = '' }) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const defaultParams = { section_id: sectionId, name: '' }

  const [params, setParams] = useState(defaultParams)
  const options = {
    onSuccess: (data) => {
      handleClose()
      queryClient.invalidateQueries('dashboards')
      navigate(`/dashboards/${sectionName.toLowerCase().replace(/\s+/g, '-').replace(' ', '-')}?dashboard_id=${data.id}`)
    }
  }

  const queryParams = {
    section_id: sectionId || params.sectionId,
    name: params.name
  }

  const { mutate } = useCreateDashboard({
    params: queryParams,
    options
  })

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        <img src={Shield} style={{ width: 15 }} alt="ThirdLine Shield" /> Create
        View
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Name of View</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="View Name"
                  onChange={(e) =>
                    setParams({ ...params, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          {!sectionId && (
            <>
              <div className="h5 mt-4">Select Module</div>
              <ModulePicker
                setParams={setParams}
                params={params}
                limitModules={['AP', 'GL', 'PO', 'PCard', 'Vendor', 'VO']}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => mutate()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default NewView
