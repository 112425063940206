import React from 'react'
import NumberField from 'shared/forms/NumberField'

function BgMinBudgetPercentRemaining({ params, setParams }) {
  return (
    <NumberField
      params={params}
      setParams={setParams}
      title="Min Budget % Remaining"
      showTitle={true}
      accessorKey="bgMinBudgetPercentRemaining"
      isPercent={true}
    />
  )
}

export default BgMinBudgetPercentRemaining
