import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { useTableColumns } from 'api/hooks/use-table-columns'
import UpdateColumn from './update-column'

function ColumnSelect({
  params,
  setParams,
  sectionId,
  dashboardId,
  currentColumns
}) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const queryParams = {
    section_id: sectionId
  }

  const { isSuccess: isColumnsSuccess, data: columns } = useTableColumns({
    params: queryParams,
    options: {}
  })

  return (
    <React.Fragment>
      <Button
        className="text-dark outline-dark bg-light me-2"
        variant="light"
        onClick={handleShow}
        size="sm"
      >
        <FontAwesomeIcon icon={faColumns} className="me-2" />
        Columns
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Columns</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isColumnsSuccess &&
            columns.map((column) => (
              <UpdateColumn
                key={`column-${column.id}`}
                column={column}
                dashboardId={dashboardId}
                currentColumns={currentColumns}
              />
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default ColumnSelect
