import React from 'react'
import DateField from 'shared/forms/DateField'

function PoGlEffectiveDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="PO GL Effective Date"
      showTitle={true}
      accessorKey="PoGlEffectiveDate"
      sectionAbbreviation="po"
    />
  )
}

export default PoGlEffectiveDate