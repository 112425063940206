import React from 'react'
import DateField from 'shared/forms/DateField'

function CheckClearDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Check Clear Date"
      showTitle={true}
      accessorKey="CheckClearDate"
      sectionAbbreviation="ap"
    />
  )
}

export default CheckClearDate