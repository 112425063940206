import React from 'react'
import useDashboardStats from 'api/hooks/use-dashboard-stats'
import CenteredSpinner from 'shared/centered-spinner'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function DashboardPopulation({
  params,
  setParams,
  sectionUnit,
  sectionName,
  isFilterOpen
}) {
  const queryParams = {
    dashboardId: params.dashboardId,
    section_id: params.section_id,
    time_period: params.time_period,
    risk_threshold: params.risk_threshold,
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount,
    vendors: params.vendors
      .filter((v) => v.options === 'include')
      .map((v) => v.id)
      .join('+'),
    excluded_vendors: params.vendors
      .filter((v) => v.options === 'exclude')
      .map((v) => v.id)
      .join('+'),
    employees: params.employees
      .filter((e) => e.options === 'include')
      .map((e) => e.id)
      .join('+'),
    excluded_employees: params.employees
      .filter((e) => e.options === 'exclude')
      .map((e) => e.id)
      .join('+'),
    analytics: params.analytics.map((a) => a.id).join('+'),
    org_ids: params.orgs.join('+'),
    object_ids: params.objects.join('+'),
    project_ids: params.projects.join('+'),
    seg_one_ids: params.segOnes.join('+'),
    seg_two_ids: params.segTwos.join('+'),
    seg_three_ids: params.segThrees.join('+'),
    seg_four_ids: params.segFours.join('+'),
    seg_five_ids: params.segFives.join('+'),
    seg_six_ids: params.segSixes.join('+'),
    seg_seven_ids: params.segSevens.join('+'),
    seg_eight_ids: params.segEights.join('+')
  }
  const options = {
    enabled: !isFilterOpen
  }
  const { isSuccess, isLoading, data } = useDashboardStats({
    params: queryParams,
    options
  })

  return (
    <Card>
      <Card.Body>
        {isLoading && <CenteredSpinner />}
        {isSuccess && (
          <>
            <div className="text-muted mb-2">
              Total Population
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    The totals within the selected time period. These numbers
                    will not change when you apply filters.
                  </Tooltip>
                }
              >
                <span className="ms-2" style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </OverlayTrigger>
            </div>
            <Row>
              <Col className="border-end">
                <h4>{Math.round(data.total_transactions).toLocaleString()}</h4>{' '}
                <Card.Text className="text-muted">{sectionUnit}s</Card.Text>
              </Col>
              <Col className="border-end">
                <>
                  {sectionName !== 'Vendor Changes' && (
                    <>
                      <h4>${Math.round(data.total_amount).toLocaleString()}</h4>
                      <Card.Text className="text-muted">
                        {sectionUnit}s Total
                      </Card.Text>
                    </>
                  )}
                  {sectionName === 'Vendor Changes' && (
                    <>
                      <h4>{data.new_vendor_count}</h4>
                      <Card.Text className="text-muted">New Vendors</Card.Text>
                    </>
                  )}
                </>
              </Col>
              <Col className="border-end">
                <h4>{data.start_date}</h4>
                <Card.Text className="text-muted">Start Date</Card.Text>
              </Col>
              <Col>
                <h4>{data.end_date}</h4>
                <Card.Text className="text-muted">End Date</Card.Text>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default DashboardPopulation
