import React, { useState } from 'react'
import TransactionTable from 'transactions/table'
import Download from 'transactions/table/actions'
import ColumnSelect from 'dashboard/column-select'
import { Row, Col } from 'react-bootstrap'

function TopTransactions({
  setParams,
  params,
  sectionUnit,
  sectionName,
  dashboardId,
  dashboard
}) {
  const showCleared = useState(false)

  const queryParams = {
    perPage: 25,
    orderBy: 'risk_score',
    order: 'desc',
    section_id: params.section_id,
    minimumAmount: params.minimum_amount,
    maximumAmount: params.maximum_amount,
    showCleared: showCleared,
    vendors: params.vendors.filter((v) => v.options === 'include'),
    excludedVendors: params.vendors.filter((v) => v.options === 'exclude'),
    employees: params.employees.filter((e) => e.options === 'include'),
    excludedEmployees: params.employees.filter((e) => e.options === 'exclude'),
    analytics: params.analytics,
    org_ids: params.orgs,
    object_ids: params.objects,
    project_ids: params.projects,
    seg_one_ids: params.segOnes,
    seg_two_ids: params.segTwos,
    seg_three_ids: params.segThrees,
    seg_four_ids: params.segFours,
    seg_five_ids: params.segFives,
    seg_six_ids: params.segSixes,
    seg_seven_ids: params.segSevens,
    seg_eight_ids: params.segEights,
    orgs_operator: params.orgsOperator,
    objects_operator: params.objectsOperator,
    projects_operator: params.projectsOperator,
    seg_ones_operator: params.segOnesOperator,
    seg_twos_operator: params.segTwosOperator,
    seg_threes_operator: params.segThreesOperator,
    seg_fours_operator: params.segFoursOperator,
    seg_fives_operator: params.segFivesOperator,
    seg_sixes_operator: params.segSixesOperator,
    seg_sevens_operator: params.segSevensOperator,
    seg_eights_operator: params.segEightsOperator,
    poIsPendingWorkflow: params?.poIsPendingWorkflow,
    poMinPoLiquidatedAmount: params?.poMinPoLiquidatedAmount,
    poMaxPoLiquidatedAmount: params?.poMaxPoLiquidatedAmount,
    poMinPoRemainingAmount: params?.poMinPoRemainingAmount,
    poMaxPoRemainingAmount: params?.poMaxPoRemainingAmount,
    poMinPoPercentLiquidated: params?.poMinPoPercentLiquidated,
    poMaxPoPercentLiquidated: params?.poMaxPoPercentLiquidated,
    poMinReqToPoCreation: params?.poMinReqToPoCreation,
    poMinReqPreparedDate: params?.poMinReqPreparedDate,
    poMaxReqPreparedDate: params?.poMaxReqPreparedDate,
    poMinPoEntryDate: params?.poMinPoEntryDate,
    poMaxPoEntryDate: params?.poMaxPoEntryDate,
    poMinPoChangeDate: params?.poMinPoChangeDate,
    poMaxPoChangeDate: params?.poMaxPoChangeDate,
    poMinPoGlEffectiveDate: params?.poMinPoGlEffectiveDate,
    poMaxPoGlEffectiveDate: params?.poMaxPoGlEffectiveDate,
    poMinPoPostingDate: params?.poMinPoPostingDate,
    poMaxPoPostingDate: params?.poMaxPoPostingDate,
    // AP Fields
    apMinInvoiceToEntryDays: params?.apMinInvoiceToEntryDays,
    apMinEntryToPaymentDays: params?.apMinEntryToPaymentDays,
    apMinTotalDaysToPayment: params?.apMinTotalDaysToPayment,
    apMaxTotalDaysToPayment: params?.apMaxTotalDaysToPayment,
    apMinInvoiceDate: params?.apMinInvoiceDate,
    apMaxInvoiceDate: params?.apMaxInvoiceDate,
    apMinInvoiceDueDate: params?.apMinInvoiceDueDate,
    apMaxInvoiceDueDate: params?.apMaxInvoiceDueDate,
    apCheckType: params?.apCheckType,
    apMinCheckDate: params?.apMinCheckDate,
    apMaxCheckDate: params?.apMaxCheckDate,
    apMinCheckClearDate: params?.apMinCheckClearDate,
    apMaxCheckClearDate: params?.apMaxCheckClearDate,
    poStatusCode: params?.poStatusCode,
    // GL Fields
    glMinEntryToEffectiveDays: params?.glMinEntryToEffectiveDays,
    glMaxEntryToEffectiveDays: params?.glMaxEntryToEffectiveDays,
    glPeriod: params?.glPeriod,
    glMinEntryDate: params?.glMinEntryDate,
    glMaxEntryDate: params?.glMaxEntryDate,
    glMinEffectiveDate: params?.glMinEffectiveDate,
    glMaxEffectiveDate: params?.glMaxEffectiveDate,
    glMinPostedDate: params?.glMinPostedDate,
    glMaxPostedDate: params?.glMaxPostedDate,
    // VO Fields
    voVendorStatus: params?.voVendorStatus,
    voLastInvoiceOnOption: params?.voLastInvoiceOnOption,
    voMinLastInvoiceOn: params?.voMinLastInvoiceOn,
    voMaxLastInvoiceOn: params?.voMaxLastInvoiceOn,
    voCurrentYearPoSumOption: params?.voCurrentYearPoSumOption,
    voMinCurrentYearPoSum: params?.voMinCurrentYearPoSum,
    voMaxCurrentYearPoSum: params?.voMaxCurrentYearPoSum,
    voIs800Number: params?.voIs800Number,
    voIsPoBox: params?.voIsPoBox,
    voIsDuplicateEin: params?.voIsDuplicateEin,
    // BG Fields
    bgAccountType: params?.bgAccountType,
    bgBudgetYear: params?.bgBudgetYear,
    bgMinBudgetPercentOfOriginal: params?.bgMinBudgetPercentOfOriginal,
    bgMaxBudgetPercentOfOriginal: params?.bgMaxBudgetPercentOfOriginal,
    bgMinBudgetPercentRemaining: params?.bgMinBudgetPercentRemaining,
    bgMaxBudgetPercentRemaining: params?.bgMaxBudgetPercentRemaining,
    bgAccountLastUpdateOption: params?.bgAccountLastUpdateOption,
    bgMinAccountLastUpdate: params?.bgMinAccountLastUpdate,
    bgMaxAccountLastUpdate: params?.bgMaxAccountLastUpdate
  }

  return (
    <div
      className="bg-white border border-1 rounded"
      style={{ borderColor: '#D2D2D2' }}
    >
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center p-2">
            <div className="fw-bold">{sectionUnit && `${sectionUnit}s`}</div>
            <div className="d-flex">
              <div className="fw-bold">
                <ColumnSelect
                  params={params}
                  setParams={setParams}
                  sectionId={params.section_id}
                  dashboardId={dashboardId}
                  currentColumns={dashboard?.columns || []}
                />
              </div>
              <div className="fw-bold me-2">
                <Download
                  defaultName={'Export'}
                  source="Views"
                  tableFilters={queryParams}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {queryParams.section_id.length > 0 && (
        <TransactionTable
          filtersEnabled={false}
          downloadButton={false}
          sectionId={queryParams.section_id}
          orderBy={queryParams.orderBy}
          perPage={queryParams.perPage}
          startOn={queryParams.startOn}
          endOn={queryParams.endOn}
          vendors={queryParams.vendors}
          minimumAmount={queryParams.minimumAmount}
          maximumAmount={queryParams.maximumAmount}
          showCleared={queryParams.showCleared}
          excludedVendors={queryParams.excludedVendors}
          analytic={queryParams.analytics}
          employees={queryParams.employees}
          excludedEmployees={queryParams.excludedEmployees}
          orgs={queryParams.org_ids}
          objects={queryParams.object_ids}
          projects={queryParams.project_ids}
          segOnes={queryParams.seg_one_ids}
          segTwos={queryParams.seg_two_ids}
          segThrees={queryParams.seg_three_ids}
          segFours={queryParams.seg_four_ids}
          segFives={queryParams.seg_five_ids}
          segSixes={queryParams.seg_six_ids}
          segSevens={queryParams.seg_seven_ids}
          segEights={queryParams.seg_eight_ids}
          riskScore={queryParams.riskScore}
          orgsOperator={queryParams.orgs_operator}
          objectsOperator={queryParams.objects_operator}
          projectsOperator={queryParams.projects_operator}
          segOnesOperator={queryParams.seg_ones_operator}
          segTwosOperator={queryParams.seg_twos_operator}
          segThreesOperator={queryParams.seg_threes_operator}
          segFoursOperator={queryParams.seg_fours_operator}
          segFivesOperator={queryParams.seg_fives_operator}
          segSixesOperator={queryParams.seg_sixes_operator}
          segSevensOperator={queryParams.seg_sevens_operator}
          segEightsOperator={queryParams.seg_eights_operator}
          riskScoreOperator={queryParams.riskScore}
          poIsPendingWorkflow={queryParams.poIsPendingWorkflow}
          poMinReqToPoCreation={queryParams.poMinReqToPoCreation}
          poStatusCode={queryParams.poStatusCode}
          poMinPoLiquidatedAmount={queryParams.poMinPoLiquidatedAmount}
          poMaxPoLiquidatedAmount={queryParams.poMaxPoLiquidatedAmount}
          poMinPoRemainingAmount={queryParams.poMinPoRemainingAmount}
          poMaxPoRemainingAmount={queryParams.poMaxPoRemainingAmount}
          poMinPoPercentLiquidated={queryParams.poMinPoPercentLiquidated}
          poMaxPoPercentLiquidated={queryParams.poMaxPoPercentLiquidated}
          poMinReqPreparedDate={queryParams.poMinReqPreparedDate}
          poMaxReqPreparedDate={queryParams.poMaxReqPreparedDate}
          poMinPoEntryDate={queryParams.poMinPoEntryDate}
          poMaxPoEntryDate={queryParams.poMaxPoEntryDate}
          poMinPoChangeDate={queryParams.poMinPoChangeDate}
          poMaxPoChangeDate={queryParams.poMaxPoChangeDate}
          poMinPoGlEffectiveDate={queryParams.poMinPoGlEffectiveDate}
          poMaxPoGlEffectiveDate={queryParams.poMaxPoGlEffectiveDate}
          poMinPoPostingDate={queryParams.poMinPoPostingDate}
          poMaxPoPostingDate={queryParams.poMaxPoPostingDate}
          apMinInvoiceDate={queryParams.apMinInvoiceDate}
          apMaxInvoiceDate={queryParams.apMaxInvoiceDate}
          apMinInvoiceDueDate={queryParams.apMinInvoiceDueDate}
          apMaxInvoiceDueDate={queryParams.apMaxInvoiceDueDate}
          apMinInvoiceToEntryDays={queryParams.apMinInvoiceToEntryDays}
          apMinEntryToPaymentDays={queryParams.apMinEntryToPaymentDays}
          apMinTotalDaysToPayment={queryParams.apMinTotalDaysToPayment}
          apMaxTotalDaysToPayment={queryParams.apMaxTotalDaysToPayment}
          apCheckType={queryParams.apCheckType}
          apMinCheckClearDate={queryParams.apMinCheckClearDate}
          apMaxCheckClearDate={queryParams.apMaxCheckClearDate}
          apMinCheckDate={queryParams.apMinCheckDate}
          apMaxCheckDate={queryParams.apMaxCheckDate}
          glMinEntryToEffectiveDays={queryParams.glMinEntryToEffectiveDays}
          glMaxEntryToEffectiveDays={queryParams.glMaxEntryToEffectiveDays}
          glPeriod={queryParams.glPeriod}
          glMinEntryDate={queryParams.glMinEntryDate}
          glMaxEntryDate={queryParams.glMaxEntryDate}
          glMinEffectiveDate={queryParams.glMinEffectiveDate}
          glMaxEffectiveDate={queryParams.glMaxEffectiveDate}
          glMinPostedDate={queryParams.glMinPostedDate}
          glMaxPostedDate={queryParams.glMaxPostedDate}
          voVendorStatus={queryParams.voVendorStatus}
          voLastInvoiceOnOption={queryParams.voLastInvoiceOnOption}
          voMinLastInvoiceOn={queryParams.voMinLastInvoiceOn}
          voMaxLastInvoiceOn={queryParams.voMaxLastInvoiceOn}
          voCurrentYearPoSumOption={queryParams.voCurrentYearPoSumOption}
          voMinCurrentYearPoSum={queryParams.voMinCurrentYearPoSum}
          voMaxCurrentYearPoSum={queryParams.voMaxCurrentYearPoSum}
          voIs800Number={queryParams.voIs800Number}
          voIsPoBox={queryParams.voIsPoBox}
          voIsDuplicateEin={queryParams.voIsDuplicateEin}
          bgAccountType={queryParams.bgAccountType}
          bgBudgetYear={queryParams.bgBudgetYear}
          bgMinBudgetPercentOfOriginal={
            queryParams.bgMinBudgetPercentOfOriginal
          }
          bgMaxBudgetPercentOfOriginal={
            queryParams.bgMaxBudgetPercentOfOriginal
          }
          bgMinBudgetPercentRemaining={queryParams.bgMinBudgetPercentRemaining}
          bgMaxBudgetPercentRemaining={queryParams.bgMaxBudgetPercentRemaining}
          bgMinAccountLastUpdate={queryParams.bgMinAccountLastUpdate}
          bgMaxAccountLastUpdate={queryParams.bgMaxAccountLastUpdate}
          source={`${sectionName} - ${sectionUnit}s`}
          columns={dashboard?.columns?.map((c) => c.column_name) || []}
          footerEnabled={true}
          title={''}
          dashboardId={dashboardId}
          showStats={true}
        />
      )}
    </div>
  )
}

export default TopTransactions
