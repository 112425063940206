import React from 'react'
import Col from 'react-bootstrap/Col'
import NumberField from 'shared/forms/NumberField'

function CurrentYearPoSum({ params, setParams }) {
  return (
    <>
      <Col>
        <NumberField
          params={params}
          setParams={setParams}
          title="Current Year PO Sum Min"
          showTitle={true}
          accessorKey="voMinCurrentYearPoSum"
        />
      </Col>
      <Col>
        <NumberField
          params={params}
          setParams={setParams}
          title="Current Year PO Sum Max"
          showTitle={true}
          accessorKey="voMaxCurrentYearPoSum"
        />
      </Col>
    </>
  )
}

export default CurrentYearPoSum
