import React from 'react';
import CheckboxField from 'shared/forms/CheckboxField';

const Is800Number = ({ params, setParams }) => {
  return (
    <CheckboxField
      params={params}
      setParams={setParams}
      accessorKey="voIs800Number"
      label="Is 800 Number"
      helperText=""
    />
  );
};

export default Is800Number;