import { useContext } from 'react'
import { useMutation } from 'react-query'
import { apiPost, apiPatch, apiDelete } from 'api'
import AuthContext from 'context/auth-context'

const createDashboardColumn = async (token, params) => {
  const objectName = 'dashboard_column'
  const url = `dashboards/${params.dashboard_id}/dashboard_columns`

  const data = await apiPost({ url, token, objectName, params })
  return data
}

export function useCreateDashboardColumn({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return createDashboardColumn(token, params, params.dashboard_id)
    },
    { ...options }
  )
}

const updateDashboardColumn = async (token, params, id) => {
  const objectName = 'dashboard_column'
  const url = `dashboard_columns/${id}`

  const data = await apiPatch({ url, token, objectName, params })
  return data
}

export function useUpdateDashboardColumns({ params, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return updateDashboardColumn(token, params, params.dashboard_column_id)
    },
    { ...options }
  )
}

const deleteDashboardColumn = async (token, id) => {
  const url = `dashboard_columns/${id}`

  const data = await apiDelete({ url, token })
  return data
}

export function useDeleteDashboardColumn({ id, options }) {
  const token = useContext(AuthContext)

  return useMutation(
    () => {
      return deleteDashboardColumn(token, id)
    },
    { ...options }
  )
}
