import pCardIcon from 'assets/Icons/analytics-purchasing-card.svg'
import arIcon from 'assets/Icons/analytics-accounts-receivable.svg'
import glIcon from 'assets/Icons/analytics-general-ledger.svg'
import apIcon from 'assets/Icons/analytics-accounts-payable.svg'
import hrIcon from 'assets/Icons/analytics-human-resources.svg'
import payrollIcon from 'assets/Icons/analytics-payroll.svg'
import purchasingIcon from 'assets/Icons/analytics-purchasing.svg'
import sodIcon from 'assets/Icons/analytics-segregation-of-duties.svg'
import teIcon from 'assets/Icons/analytics-travel-entertainment.svg'
import vendorIcon from 'assets/Icons/analytics-vendor.svg'
import budgetIcon from 'assets/Icons/analytics-slfrf.svg'

const icons = {
  AP: apIcon,
  AR: arIcon,
  BG: budgetIcon,
  GL: glIcon,
  HR: hrIcon,
  PR: payrollIcon,
  PC: pCardIcon,
  PO: purchasingIcon,
  SOD: sodIcon,
  TE: teIcon,
  Vendor: vendorIcon,
  VO: vendorIcon
}

export const getIcon = (sectionAbbreviation) => {
  return icons[sectionAbbreviation]
}

