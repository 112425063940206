import React from 'react'
import CheckboxField from 'shared/forms/CheckboxField'

const IsPoBox = ({ params, setParams }) => {
  return (
    <CheckboxField
      params={params}
      setParams={setParams}
      accessorKey="voIsPoBox"
      label="Is PO Box"
      helperText=""
    />
  )
}

export default IsPoBox