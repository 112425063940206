import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'react-bootstrap/Table'
import VendorCard from './vendor-card'
import EmployeeCard from './drawer/employee-card'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Spinner from 'react-bootstrap/Spinner'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { audience } from 'api/helpers'
import { apiGet } from 'api'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import { formatDateSlash } from 'helpers/datetime'
import { twoDigitMoney } from 'helpers/utils'
import FlagCount from 'shared/flag-count'
import DrawerHeader from 'transactions/drawer/header'
import VendorChange from 'shared/vendor-change-badge'
import Features from './drawer/features'
import AnalyticsTable from 'transactions/drawer/analytics'
import DrawerAllocations from './drawer/allocations'
import RiskBadge from 'shared/badges/risk-badge'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'

function ProcessingAuditReportDrawer({
  transactions,
  transactionId,
  auditReportId,
  show,
  setShow
}) {
  const [currentTransaction, setCurrentTransaction] = useState(transactionId)
  const queryClient = useQueryClient()
  const handleClose = () => {
    queryClient.invalidateQueries('transactions')
    queryClient.invalidateQueries([`reports/${auditReportId}`])
    queryClient.invalidateQueries(
      `audit_reports/${auditReportId}/transactions/${currentTransaction}`
    )
    setCurrentTransaction(transactionId)
    setShow(false)
  }

  const { getAccessTokenSilently } = useAuth0()

  const { isSuccess, isFetching, isLoading, data } = useQuery(
    `audit_reports/${auditReportId}/transactions/${currentTransaction}`,
    async () => {
      const url = `audit_reports/${auditReportId}/transactions/${currentTransaction}`
      const token = await getAccessTokenSilently({ audience })
      const data = await apiGet({ url, token })
      return data
    },
    { enabled: show, keepPreviousData: true }
  )

  return (
    <React.Fragment>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll="true"
        backdrop="true"
        style={{ width: '60%' }}
      >
        {isSuccess && (
          <DrawerHeader
            transactions={transactions}
            currentTransaction={currentTransaction}
            setCurrentTransaction={setCurrentTransaction}
            transaction={data.transaction}
            handleClose={handleClose}
          />
        )}

        <Offcanvas.Body>
          {(isLoading || isFetching) && (
            <Row>
              <Col sm="auto"></Col>
              <Col sm="auto">
                <div className="my-5 py-5">
                  <Spinner
                    animation="grow"
                    variant="warning"
                    className="my-5"
                  />
                </div>
              </Col>
              <Col sm="auto"></Col>
            </Row>
          )}
          {isSuccess && !isFetching && (
            <>
              <h5>Testing Attributes</h5>
              <Features
                transactionReview={data.transaction_review}
                features={data.features}
              />
            </>
          )}

          <Row className="mb-4 mt-4">
            <Col sm={3}>
              <div className="fw-light text-muted">Date</div>
              <div className="h4">
                {isSuccess
                  ? formatDateSlash(data.transaction.transaction_on)
                  : ''}
              </div>
            </Col>
            <Col sm={3}>
              <div className="fw-light text-muted">Amount</div>
              <div className="h4">
                {isSuccess && data.transaction.section_name !== 'Vendor Changes'
                  ? twoDigitMoney(data.transaction.line_amount)
                  : ''}

                {isSuccess &&
                  data.transaction.section_name === 'Vendor Changes' && (
                    <VendorChange />
                  )}
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12}>Details</Col>
            {isSuccess &&
              data.transaction.details?.split('||').map((d, i) => (
                <Col sm={6} key={`details-${i}`}>
                  <span
                    key={`keyword-${d}`}
                    className="mb-0 ml-4 fw-light text-muted small"
                  >
                    {d}
                  </span>
                </Col>
              ))}
          </Row>

          {isSuccess && data.transaction.allocations.length > 0 && (
            <DrawerAllocations allocations={data.transaction.allocations} />
          )}

          <Row className="mb-4 mt-2">
            <Col></Col>
          </Row>

          <Row className="mb-4">
            <Col sm={6}>
              <Card className="h-100">
                <Card.Body>
                  <ErrorBoundary
                    fallback={DefaultError}
                    beforeCapture={(scope) => {
                      scope.setTag('component', 'VendorCard')
                    }}
                  >
                    {isSuccess && (
                      <VendorCard vendor={data.transaction.vendor} />
                    )}
                  </ErrorBoundary>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card className="h-100">
                <Card.Body>
                  <ErrorBoundary
                    fallback={DefaultError}
                    beforeCapture={(scope) => {
                      scope.setTag('component', 'VendorCard')
                    }}
                  >
                    {isSuccess && (
                      <EmployeeCard employee={data.transaction.employee} />
                    )}
                  </ErrorBoundary>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <hr />

          <h5 className="mt-4">
            Risk Score{' '}
            {isSuccess && (
              <RiskBadge
                count={parseFloat(data.transaction.risk_score)}
                showCount={true}
              />
            )}
            <span className="ms-4"></span>
            Analytics Flagged{' '}
            {isSuccess && <FlagCount count={data.analytics.length} />}
          </h5>

          {isSuccess && (
            <AnalyticsTable
              analytics={data.analytics}
              reportAnalytics={data.report_analytics}
            />
          )}

          <hr />

          {isSuccess && data.employee && false && (
            <Table responsive className="border" size="sm">
              <thead>
                <tr>
                  <th>Employee Permission Conflicts</th>
                </tr>
              </thead>
              <tbody>
                {data.employee.conflicts.length === 0 && (
                  <tr>
                    <td>No Conflicts Found</td>
                  </tr>
                )}
                {data.employee.conflicts.map((a) => (
                  <tr key={`conflicts-${a.id}`}>
                    <td className="border-right">
                      {a.name}
                      <div
                        style={{ fontSize: '0.9rem' }}
                        className="text-muted fw-light"
                      >
                        {a.description}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  )
}

ProcessingAuditReportDrawer.propTypes = {
  transactionId: PropTypes.string.isRequired
}

export default ProcessingAuditReportDrawer
