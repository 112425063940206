import React from 'react'
import DateField from 'shared/forms/DateField'

function CheckDate({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Check Date"
      showTitle={true}
      accessorKey="CheckDate"
      sectionAbbreviation="ap"
    />
  )
}

export default CheckDate