import React from 'react'
import SelectField from 'shared/forms/SelectField'

function Year({ params, setParams }) {
  // TODO: Make options dynamic based on what is in the DB?
  const years = [
    { value: '0', label: 'None' },
    { value: '2025', label: '2025' },
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' }
  ]

  return (
    <SelectField
      params={params}
      setParams={setParams}
      options={years}
      accessorKey="bgBudgetYear"
      title="Year"
      showTitle={false}
      placeholder="Select Year"
    />
  )
}

export default Year
