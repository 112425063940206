import React from 'react'
import DateField from 'shared/forms/DateField'

function LastInvoiceOn({ params, setParams }) {
  return (
    <DateField
      params={params}
      setParams={setParams}
      title="Last Invoice"
      showTitle={true}
      accessorKey="LastInvoiceOn"
      sectionAbbreviation="vo"
    />
  )
}

export default LastInvoiceOn
