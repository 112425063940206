import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MoreFilters from './MoreFilters'
import VendorFilter from './vendor-filter'
import EmployeeFilter from './employee-filter'
import AccountsFilter from '../../shared/filters/accounts'
import AnalyticsFilters from './analytics'
import YearFilter from './budget/BudgetYear'
import Button from 'react-bootstrap/Button'

function DashboardFilters({
  params,
  setParams,
  isFilterOpen,
  setIsFilterOpen,
  includedFilters,
  sectionName,
  showStats,
  setShowStats
}) {
  return (
    <Row>
      {includedFilters.includes('year') && (
        <Col sm="auto">
          <YearFilter
            params={params}
            setParams={setParams}
          />
        </Col>
      )}
      {includedFilters.includes('vendor') && (
        <Col sm="auto">
          <VendorFilter
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
        </Col>
      )}
      {includedFilters.includes('employee') && (
        <Col sm="auto">
          <EmployeeFilter
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
        </Col>
      )}
      {includedFilters.includes('accounts') && (
        <Col sm="auto">
          <AccountsFilter
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            buttonSize=""
          />
        </Col>
      )}
      {includedFilters.includes('analytics') && (
        <Col sm="auto">
          <AnalyticsFilters
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
        </Col>
      )}
      {includedFilters.includes('more') && (
        <Col sm="auto">
          <MoreFilters
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            sectionName={sectionName}
          />
        </Col>
      )}

      {false && (
        <Col className="ml-auto text-right">
          <Button variant="link" onClick={() => setShowStats(!showStats)}>
            {showStats ? 'Hide' : 'Show'} Stats
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default DashboardFilters
