import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import AmountFilter from './amount'
import IsPending from './purchasing/IsPending'
import ReqToPoCreation from './purchasing/ReqToPoCreation'
import PoStatusCode from './purchasing/PoStatusCode'
import PoMinPoLiquidatedAmount from './purchasing/PoMinPoLiquidatedAmount'
import PoMaxPoLiquidatedAmount from './purchasing/PoMaxPoLiquidatedAmount'
import PoMinPoRemainingAmount from './purchasing/PoMinPoRemainingAmount'
import PoMaxPoRemainingAmount from './purchasing/PoMaxPoRemainingAmount'
import PoMinPoPercentLiquidated from './purchasing/PoMinPoPercentLiquidated'
import PoMaxPoPercentLiquidated from './purchasing/PoMaxPoPercentLiquidated'
import ReqPreparedDate from './purchasing/ReqPreparedDate'
import PoEntryDate from './purchasing/PoEntryDate'
import PoChangeDate from './purchasing/PoChangeDate'
import PoGlEffectiveDate from './purchasing/PoGlEffectiveDate'
import PoPostingDate from './purchasing/PoPostingDate'
import MinEntryToEffectiveDays from './general_ledger/MinEntryToEffectiveDays'
import MaxEntryToEffectiveDays from './general_ledger/MaxEntryToEffectiveDays'
import EntryToPaymentDays from './accounts_payable/EntryToPaymentDays'
import MinTotalDaysToPayment from './accounts_payable/MinTotalDaysToPayment'
import MaxTotalDaysToPayment from './accounts_payable/MaxTotalDaysToPayment'
import InvoiceToEntryDays from './accounts_payable/InvoiceToEntryDays'
import Period from './general_ledger/period'
import EntryDate from './general_ledger/EntryDate'
import EffectiveDate from './general_ledger/EffectiveDate'
import PostedDate from './general_ledger/PostedDate'
import AccountType from './budget/AccountType'
import VendorStatus from './vendors/Status'
import LastInvoiceOn from './vendors/LastInvoiceOn'
import CurrentYearPoSum from './vendors/CurrentYearPoSum'
import IsPoBox from './vendors/IsPoBox'
import IsDuplicateEin from './vendors/IsDuplicateEin'
import Is800Number from './vendors/Is800Number'
import BgMinBudgetPercentRemaining from './budget/BgMinBudgetPercentRemaining'
import BgMaxBudgetPercentRemaining from './budget/BgMaxBudgetPercentRemaining'
import BgMinBudgetPercentOfOriginal from './budget/BgMinBudgetPercentOfOriginal'
import BgMaxBudgetPercentOfOriginal from './budget/BgMaxBudgetPercentOfOriginal'
import BgAccountLastUpdate from './budget/BgAccountLastUpdate'
import CheckType from './accounts_payable/CheckType'
import InvoiceDate from './accounts_payable/InvoiceDate'
import InvoiceDueDate from './accounts_payable/InvoiceDueDate'
import CheckDate from './accounts_payable/CheckDate'
import CheckClearDate from './accounts_payable/CheckClearDate'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'

function MoreFilters({ params, setParams, setIsFilterOpen, sectionName }) {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setIsFilterOpen(false)
    setShow(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }

  const amountParams = {
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount
  }

  const { mutate } = useUpdateDashboard({
    params: amountParams,
    id: params.dashboardId
  })

  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.minimum_amount, params.maximum_amount])

  const isFiltered =
    params.minimum_amount ||
    params.maximum_amount ||
    params?.poMinReqToPoCreation ||
    params?.poIsPendingWorkflow ||
    params?.poStatusCode ||
    params?.apMinInvoiceToEntryDays ||
    params?.apMinEntryToPaymentDays ||
    params?.apMinTotalDaysToPayment ||
    params?.apMaxTotalDaysToPayment ||
    params?.apMinInvoiceDate ||
    params?.apMaxInvoiceDate ||
    params?.apMinInvoiceDueDate ||
    params?.apMaxInvoiceDueDate ||
    params?.apCheckType ||
    params?.apMinCheckDate ||
    params?.apMaxCheckDate ||
    params?.apMinCheckClearDate ||
    params?.apMaxCheckClearDate ||
    params?.poMinPoLiquidatedAmount ||
    params?.poMaxPoLiquidatedAmount ||
    params?.poMinPoRemainingAmount ||
    params?.poMaxPoRemainingAmount ||
    params?.poMinPoPercentLiquidated ||
    params?.poMaxPoPercentLiquidated ||
    params?.poMinReqPreparedDate ||
    params?.poMaxReqPreparedDate ||
    params?.poMinPoEntryDate ||
    params?.poMaxPoEntryDate ||
    params?.poMinPoChangeDate ||
    params?.poMaxPoChangeDate ||
    params?.poMinPoGlEffectiveDate ||
    params?.poMaxPoGlEffectiveDate ||
    params?.poMinPoPostingDate ||
    params?.poMaxPoPostingDate ||
    params?.glMinEntryToEffectiveDays ||
    params?.glMaxEntryToEffectiveDays ||
    params?.glPeriod ||
    params?.glMinEffectiveDate ||
    params?.glMaxEffectiveDate ||
    params?.glMinPostedDate ||
    params?.glMaxPostedDate ||
    params?.glMinEntryDate ||
    params?.glMaxEntryDate ||
    params?.voVendorStatus ||
    params?.voMinLastInvoiceOn ||
    params?.voMaxLastInvoiceOn ||
    params?.voMinCurrentYearPoSum ||
    params?.voMaxCurrentYearPoSum ||
    params?.voIsPoBox ||
    params?.voIsDuplicateEin ||
    params?.bgAccountType ||
    params?.bgMinBudgetPercentRemaining ||
    params?.bgMaxBudgetPercentRemaining ||
    params?.bgMinBudgetPercentOfOriginal ||
    params?.bgMaxBudgetPercentOfOriginal ||
    params?.bgMinBgAccountLastUpdate ||
    params?.bgMaxBgAccountLastUpdate

  return (
    <React.Fragment>
      <label className="mr-2 fw-light text-muted sr-only">
        <small>More Filters</small>
      </label>
      <div>
        <Button
          className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
          onClick={handleShow}
          style={{ border: isFiltered ? '' : '1px solid #ccc' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
        >
          <FontAwesomeIcon icon={faFilter} /> Filters
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>More Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sectionName === '' && (
            <Row>
              <Col>
                <AmountFilter
                  params={params}
                  setParams={setParams}
                  accessorKey={'minimum_amount'}
                  title="Minimum"
                />
              </Col>
              <Col>
                <AmountFilter
                  params={params}
                  setParams={setParams}
                  accessorKey={'maximum_amount'}
                  title="Maximum"
                />
              </Col>
            </Row>
          )}

          {sectionName === 'Purchasing' && (
            <>
              <h5 className="mt-4">Purchasing Process</h5>
              <Row className="mt-2">
                <Col>
                  <PoStatusCode params={params} setParams={setParams} />
                </Col>
                <Col></Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <ReqToPoCreation params={params} setParams={setParams} />
                </Col>
                <Col></Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoLiquidatedAmount
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <PoMaxPoLiquidatedAmount
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoRemainingAmount
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <PoMaxPoRemainingAmount
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoMinPoPercentLiquidated
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <PoMaxPoPercentLiquidated
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <ReqPreparedDate params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PoEntryDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <PoChangeDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <PoGlEffectiveDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <PoPostingDate params={params} setParams={setParams} />
                </Col>
              </Row>

              <h5 className="mt-4">Workflow</h5>

              <Row className="mt-2">
                <Col>
                  <IsPending params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}

          {sectionName === 'Accounts Payable' && (
            <>
              <Row className="mt-2">
                <Col>
                  <InvoiceDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <InvoiceDueDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <CheckDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <CheckClearDate params={params} setParams={setParams} />
                </Col>
              </Row>
              <h5 className="mt-4">Invoicing Process</h5>
              <Row className="mt-2">
                <Col>
                  <InvoiceToEntryDays params={params} setParams={setParams} />
                </Col>
                <Col>
                  <EntryToPaymentDays params={params} setParams={setParams} />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <MinTotalDaysToPayment
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <MaxTotalDaysToPayment
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <CheckType params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}

          {sectionName === 'General Ledger' && (
            <>
              <h5 className="mt-4">General Ledger</h5>
              <Row className="mt-2">
                <Col>
                  <EntryDate params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <EffectiveDate params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <PostedDate params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <MinEntryToEffectiveDays
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <MaxEntryToEffectiveDays
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Period params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}

          {sectionName === 'Vendors' && (
            <>
              <h5 className="mt-4">Vendors</h5>
              <Row className="mt-2">
                <Col>
                  <VendorStatus params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <LastInvoiceOn params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <CurrentYearPoSum params={params} setParams={setParams} />
              </Row>

              <Row className="mt-4">
                <Col>
                  <IsPoBox params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <IsDuplicateEin params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Is800Number params={params} setParams={setParams} />
                </Col>
              </Row>
            </>
          )}

          {sectionName === 'Budget' && (
            <>
              <h5 className="mt-4">Budget</h5>
              <Row className="mt-2">
                <Col>
                  <AccountType params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <BgAccountLastUpdate params={params} setParams={setParams} />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <BgMinBudgetPercentRemaining
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <BgMaxBudgetPercentRemaining
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <BgMinBudgetPercentOfOriginal
                    params={params}
                    setParams={setParams}
                  />
                </Col>
                <Col>
                  <BgMaxBudgetPercentOfOriginal
                    params={params}
                    setParams={setParams}
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
